import React from 'react'

import Category from '../../../components/report-details-category'
import Developer from './developer'
import Loader from '../../../components/loader'
import ReportHeader from '../../../containers/report-header'

const ReportChurnByInterval = ({
  data,
  loading
}) => (
  <div className='container'>
    <ReportHeader title={'Churn of all code'} titleStyle='reportName' showDates baseReportName='timeLeaks' />
    {loading
      ? <Loader centered size='lg' />
      : (
        <div className='row'>
          <div className='col-12'>
            <Category
              title='Developers with high churn'
              emptyText='There are no developers with such problem'
            >
              {data && data.high_churn && data.high_churn.map(d =>
                <Developer key={d.id} data={d} />
              )}
            </Category>
          </div>
          <div className='col-12'>
            <Category
              title='Developers with low churn'
              emptyText='There are no developers low time leaks in interval'
            >
              {data && data.low_churn && data.low_churn.map(d =>
                <Developer key={d.id} data={d} />
              )}
            </Category>
          </div>
        </div>
      )
    }
  </div>
)

export default ReportChurnByInterval
