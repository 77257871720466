import React from 'react'
import classnames from 'classnames'

import s from './index.module.css'

const Empty = ({text}) => (
  <div className='row justify-content-center'>
    <div className={classnames('col-auto', s.emptyText)}>
      {text}
    </div>
  </div>
)
export default ({
  children,
  title,
  emptyText
}) => (
  <div className={classnames(s.category, 'row')}>
    <div className='col-12'>
      <div className='row'>
        <div className={classnames('col', s.title)}>
          {title}
        </div>
      </div>
      {React.Children.count(children) > 0 ? children : <Empty text={emptyText} />}
    </div>
  </div>
)
