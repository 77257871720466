import { push, replace } from 'connected-react-router'
import qs from 'query-string'

import { fetchCompanies } from '../../../api'
import { COMPANIES, COMPANIES_REQUESTED, SELECT_COMPANY } from '../constants'

export const getCompanies = () => {
  return (dispatch, getState) => {
    const request = fetchCompanies()
    dispatch({
      type: COMPANIES_REQUESTED,
      payload: { request }
    })

    request.then(companies => {
      dispatch({
        type: COMPANIES,
        payload: { companies }
      })
    })
  }
}

export const selectCompany = (id, type) => {
  return (dispatch, getState) => {
    const location = getState().router.location
    // console.log(qs.stringify({...qs.parse(location.search), companyId: id, teamId: null}))
    dispatch((type === 'replace' ? replace : push)({
      ...location,
      search: qs.stringify({...qs.parse(location.search), companyId: id, teamId: null})
    }))
    dispatch({
      type: SELECT_COMPANY,
      payload: { id }
    })
  }
}
