import {
  fetchTooUnique
} from '../../../api'

import {
  TOO_UNIQUE_REQUESTED,
  TOO_UNIQUE
} from '../constants'
import {actionRequestForCompanyAndTeam} from '../../utils-actions'

export const getTooUnique = actionRequestForCompanyAndTeam(
  TOO_UNIQUE_REQUESTED,
  TOO_UNIQUE,
  fetchTooUnique
)
