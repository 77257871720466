import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import ReportLowPerformance from '../components/report-low-performance'
import {
  getLowPerformance
} from '../../../modules/report-discipline/actions/requests'

const mapStateToProps = state => ({
  currentTeam: state._global.companyData.currentTeam,
  currentCompany: state._global.companies.currentCompany,

  lowPerformance: _.pick(state.reportDiscipline.lowPerformance, ['loading', 'data'])
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getLowPerformance
}, dispatch)

class ReportLowPerformanceContainer extends PureComponent {
  componentDidMount () {
    this.props.getLowPerformance()
  }

  componentDidUpdate (prevProps) {
    if (
      this.props.currentCompany !== prevProps.currentCompany ||
      this.props.currentTeam !== prevProps.currentTeam
    ) {
      this.props.getLowPerformance()
    }
  }

  render () {
    const { lowPerformance } = this.props
    return <ReportLowPerformance
      data={lowPerformance.data}
      loading={lowPerformance.loading}
    />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportLowPerformanceContainer)
