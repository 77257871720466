const initialState = {
  data: null,
  loading: false,
  request: null
}

export const requestReducer = (actionRequested, actionSuccess) => {
  return (state = {...initialState}, action) => {
    switch (action.type) {
      case actionRequested:
        state.request && state.request.cancel()
        return {
          ...state,
          data: null,
          request: action.payload.request,
          loading: true
        }

      case actionSuccess:
        return {
          ...state,
          data: action.payload,
          request: null,
          loading: false
        }

      default:
        return state
    }
  }
}
