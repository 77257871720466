import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import ReportQualityRisks from '../components/report-quality-risks'
import {
  getOverview,
  getTooManyFixedTasks,
  getTooManyFixedCommits
} from '../../../modules/report-quality-risks/actions/requests'
import {
  toTooManyFixedTasks,
  toTooManyFixedCommits
} from '../../../modules/report-quality-risks/actions/routing'

const mapStateToProps = state => ({
  currentTeam: state._global.companyData.currentTeam,
  currentCompany: state._global.companies.currentCompany,

  maxReportDate: state._global.companyData.maxReportsDates.qualityRisks,

  overview: _.pick(state.reportQualityRisks.overview, ['loading', 'data']),
  tooManyFixedTasks: _.pick(state.reportQualityRisks.tooManyFixedTasks, ['loading', 'data']),
  tooManyFixedCommits: _.pick(state.reportQualityRisks.tooManyFixedCommits, ['loading', 'data'])
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getOverview,
  getTooManyFixedTasks,
  getTooManyFixedCommits,

  toTooManyFixedTasks,
  toTooManyFixedCommits
}, dispatch)

class ReportQualityRisksContainer extends PureComponent {
  componentDidMount () {
    this.props.getOverview()
    this.props.getTooManyFixedTasks()
    this.props.getTooManyFixedCommits()
  }

  componentDidUpdate (prevProps) {
    if (
      this.props.currentCompany !== prevProps.currentCompany ||
      this.props.currentTeam !== prevProps.currentTeam
    ) {
      this.props.getOverview()
      this.props.getTooManyFixedTasks()
      this.props.getTooManyFixedCommits()
    }
  }

  render () {
    const {
      overview, maxReportDate,
      tooManyFixedTasks, toTooManyFixedTasks,
      tooManyFixedCommits, toTooManyFixedCommits
    } = this.props

    return <ReportQualityRisks
      maxReportDate={maxReportDate}
      overview={overview}

      tooManyFixedTasks={tooManyFixedTasks}
      toTooManyFixedTasks={toTooManyFixedTasks}

      tooManyFixedCommits={tooManyFixedCommits}
      toTooManyFixedCommits={toTooManyFixedCommits}
    />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportQualityRisksContainer)
