import {
  fetchTimeLeaksHistory,
  fetchChurnByTasks,
  fetchChurnByIntervals
} from '../../../api'

import {
  OVERVIEW_REQUESTED,
  OVERVIEW,
  CHURN_BY_TASKS_REQUESTED,
  CHURN_BY_TASKS,
  CHURN_BY_INTERVAL_REQUESTED,
  CHURN_BY_INTERVAL
} from '../constants'
import {actionRequestForCompanyAndTeam} from '../../utils-actions'

export const getOverview = actionRequestForCompanyAndTeam(
  OVERVIEW_REQUESTED,
  OVERVIEW,
  fetchTimeLeaksHistory
)
export const getChurnByTasks = actionRequestForCompanyAndTeam(
  CHURN_BY_TASKS_REQUESTED,
  CHURN_BY_TASKS,
  fetchChurnByTasks
)
export const getChurnByIntervals = actionRequestForCompanyAndTeam(
  CHURN_BY_INTERVAL_REQUESTED,
  CHURN_BY_INTERVAL,
  fetchChurnByIntervals
)
