import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import ReportViolations from '../components/report-violations'
import {
  getViolations
} from '../../../modules/report-discipline/actions/requests'

const mapStateToProps = state => ({
  currentTeam: state._global.companyData.currentTeam,
  currentCompany: state._global.companies.currentCompany,

  violations: _.pick(state.reportDiscipline.violations, ['loading', 'data'])
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getViolations
}, dispatch)

class ReportViolationsContainer extends PureComponent {
  componentDidMount () {
    this.props.getViolations()
  }

  componentDidUpdate (prevProps) {
    if (
      this.props.currentCompany !== prevProps.currentCompany ||
      this.props.currentTeam !== prevProps.currentTeam
    ) {
      this.props.getViolations()
    }
  }

  render () {
    const { violations } = this.props
    return <ReportViolations
      data={violations.data}
      loading={violations.loading}
    />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportViolationsContainer)
