import React from 'react'
import classnames from 'classnames'

import s from './index.module.css'

const Empty = ({text}) => (
  <div className={classnames('row justify-content-between align-items-center', s.empty)}>
    <div className={classnames('col', 'text-center')}>
      {text}
    </div>
  </div>
)

const List = ({data, row, emptyText}) => (
  <div className={classnames('row', s.cardTable)}>
    <div className='col-12'>
      <div className={s.separator} />
    </div>
    <div className='col'>
      {data.length === 0
        ? <Empty text={emptyText} />
        : (
          <div className={s.developers}>
            {data.slice(0, 3).map(d => React.createElement(row, {key: d.id, data: d}))}
            {data.length > 3 ? (
              <div className={classnames('row align-items-center', s.moreDevs)}>
                <div className='col text-left'>
                  + {data.length - 3} more...
                </div>
              </div>
            ) : null}
          </div>
        )
      }
    </div>
  </div>
)

export default List
