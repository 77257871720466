import _ from 'lodash'

import {
  jsonPost
} from './utils'

export function fetchBusFactorOverview (companyId, teamId) {
  return jsonPost(
    `dashboard/busFactor/busFactorOverview`,
    {companyId, teamId}
  ).then(data => ({
    ...data,
    developers: [
      ..._.orderBy(data.developers.filter(d => d.uniqueness_problem_category === 'very bad'), 'author_unique_files', 'desc'),
      ..._.orderBy(data.developers.filter(d => d.uniqueness_problem_category === 'bad'), 'author_unique_files', 'desc')
    ]
  }))
}

export function fetchTooUnique (companyId, teamId) {
  return jsonPost(
    `dashboard/busFactor/tooUnique`,
    {companyId, teamId}
  )
}
