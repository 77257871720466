import React from 'react'
import ReactTooltip from 'react-tooltip'

import icon from './icon-hint.svg'
import s from './hint.module.css'

export default ({id, content}) => (
  <div>
    <div data-tip data-for={id}>
      <img src={icon} className={s.icon} alt={`Help icon for ${id}`} />
    </div>
    <ReactTooltip id={id} effect='solid'>
      {content}
    </ReactTooltip>
  </div>
)
