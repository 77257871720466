import React from 'react'
import classnames from 'classnames'

import s from './index.module.css'

const DeveloperRow = ({name, value}) => (
  <div className={classnames('row', 'align-items-center', s.developerRow)}>
    <div className='col'>
      <span className={s.developerRowName}>{name}</span>
    </div>
    <div className='col-auto'>
      <span className={s.developerRowValue}>{value}</span>
    </div>
  </div>
)
export default DeveloperRow
