import React, { Component } from 'react'
import { Line } from 'react-chartjs-2'
import 'chartjs-plugin-datalabels'
import classnames from 'classnames'
import _ from 'lodash'

import CardWithTitle from '../../../../components/card-with-title'
import s from './index.module.css'
import { prepareData, prepareOptions } from './prepare-for-chart'

const content = (data, chartColor) => (
  <div className='row'>
    <div className='col-12'>
      <div className={classnames('row', s.cardText)}>
        <div className='col'>
          <div className='row justify-content-center'>
            <div className='col text-center'>
              <span className={classnames(s.mainTextLarge, 'align-middle')}>{data.with_problems}</span>
              <span className={classnames(s.mainTextSmall, 'align-middle')}>{` / ${data.all}`}</span>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col text-center'>
              <span className={classnames(s.secondaryText, 'align-middle')}>PROBLEM DEVELOPERS</span>
            </div>
          </div>
        </div>
      </div>
      <div className={classnames('row', s.cardChart)}>
        <div className={classnames('col', s.chartPaddings)}>
          <Line
            data={prepareData(_.cloneDeep(data.history), chartColor)}
            options={prepareOptions(_.max(data.history.map(h => h.max_value)), data.history)}
          />
        </div>
      </div>
    </div>
  </div>
)
export default class CardWithChart extends Component {
  shouldComponentUpdate (nextProps, nextState) {
    return !_.isEqual(this.props, nextProps)
  }

  render () {
    const {state, title, hint, chartColor, className, onClick} = this.props
    return (
      <CardWithTitle
        title={title}
        hint={hint}
        withHover
        className={classnames(className, s.card)}
        loading={state.loading}
        empty={!state.data}
        onClick={onClick}
      >
        {state.data ? content(state.data, chartColor) : null}
      </CardWithTitle>
    )
  }
}
