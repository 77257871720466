import React, { Children } from 'react'
import classnames from 'classnames'
import _ from 'lodash'

import Hint from '../hint'
import Loader from '../loader'
import s from './card-with-title.module.css'

const hintIcon = (title, hint) => (
  <div className='col-auto'>
    <Hint id={_.snakeCase(title)} content={hint} />
  </div>
)

const emptyState = () => (
  <div className={classnames('row', 'justify-content-center', 'align-items-center', 'h-100', s.empty)}>
    <div className='col-auto'>
      <b>No data</b>
    </div>
  </div>
)

const loader = () => (
  <div className={classnames('row', 'justify-content-center', 'align-items-center', 'h-100', s.loader)}>
    <div className='col-auto'>
      <Loader />
    </div>
  </div>
)

export default ({
  loading,
  empty,
  title,
  hint,
  withHover,
  className,
  innerClassName,
  children,
  onClick
}) => (
  <div className={classnames(className)}>
    <div
      className={classnames('h-100', innerClassName, s.card, {[s.cardWithHover]: withHover})}
      onClick={() => onClick && onClick()}
    >
      <div className={classnames('row', 'align-items-center', s.title)}>
        <div className='col'>
          {title}
        </div>
        { hint ? hintIcon(title, hint) : null }
      </div>
      <div className={classnames('row', 'h-100', s.body)}>
        <div className='col'>
          {loading ? loader() : (empty ? emptyState() : Children.only(children))}
        </div>
      </div>
    </div>
  </div>
)
