import React from 'react'
import classnames from 'classnames'

import CardWithGroupedLists, { DeveloperRow } from '../../../../components/card-with-grouped-lists'
import s from './index.module.css'
import hints from '../../../../strings/hints'

const fixedTasksGroups = [{
  name: 'developers_with_too_many_fixed_tasks',
  label: 'Too many fixed tasks',
  limit: 7,
  element: ({data}) => (
    <DeveloperRow name={data.name} value={data.fixed_tasks} />
  ),
  emptyText: 'There are no developers with such problem'
}]
export const FixedTasks = ({data, loading, onClick, className}) => (
  <CardWithGroupedLists
    title='Fixed tasks'
    hint={hints.fixedTasks}
    loading={loading}
    empty={!data}
    withHover
    className={classnames(className, s.card)}
    data={data}
    onClick={onClick}
    groups={fixedTasksGroups}
  />
)

const fixedCommitsGroups = [{
  name: 'developers_with_too_many_fixed_commits',
  label: 'Too many fixed commits',
  limit: 7,
  element: ({data}) => (
    <DeveloperRow name={data.name} value={data.fixed_commits} />
  ),
  emptyText: 'There are no developers with such problem'
}]
export const FixedCommits = ({data, loading, onClick, className}) => (
  <CardWithGroupedLists
    title='Fixed commits'
    hint={hints.fixedCommits}
    loading={loading}
    empty={!data}
    withHover
    className={classnames(className, s.card)}
    data={data}
    onClick={onClick}
    groups={fixedCommitsGroups}
  />
)
