import React from 'react'
import classnames from 'classnames'

import s from './index.module.css'

export default ({
  withHover,
  className,
  innerClassName,
  onClick,
  children
}) => (
  <div className={classnames(className)}>
    <div
      className={classnames('h-100', innerClassName, s.card, {[s.cardWithHover]: withHover})}
      onClick={onClick}
    >
      {children}
    </div>
  </div>
)
