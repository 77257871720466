import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import ReportOverwork from '../components/report-overwork'
import {
  getOverwork
} from '../../../modules/report-discipline/actions/requests'

const mapStateToProps = state => ({
  currentTeam: state._global.companyData.currentTeam,
  currentCompany: state._global.companies.currentCompany,

  overwork: _.pick(state.reportDiscipline.overwork, ['loading', 'data'])
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getOverwork
}, dispatch)

class ReportOverworkContainer extends PureComponent {
  componentDidMount () {
    this.props.getOverwork()
  }

  componentDidUpdate (prevProps) {
    if (
      this.props.currentCompany !== prevProps.currentCompany ||
      this.props.currentTeam !== prevProps.currentTeam
    ) {
      this.props.getOverwork()
    }
  }

  render () {
    const { overwork } = this.props
    return <ReportOverwork
      data={overwork.data}
      loading={overwork.loading}
    />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportOverworkContainer)
