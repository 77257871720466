import React from 'react'
import classnames from 'classnames'

import CardWithOverviewChart from '../../../../components/card-with-overview-bar-chart'
import s from './index.module.css'

const chartConfig = [
  {label: 'Leaked hours by churn of all code', name: 'churn_by_intervals', color: '#FFAB40'},
  {label: 'Leaked hours by churn of code in tasks', name: 'churn_by_tasks', color: '#FF6E40'},
  {label: 'Effective hours', name: 'effective_hours', color: '#69F0AE'}
]
export default ({loading, data, className}) => (
  <CardWithOverviewChart
    className={classnames(className, s.card)}
    loading={loading}
    empty={!data || !data.data || data.data.length === 0}
    chartData={data && data.data}
    chartConfig={chartConfig}
    chartName='Time distribution'
  />
)
