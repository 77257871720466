import React from 'react'

import DeveloperRow, { Param, Name } from '../../../components/report-details-developer-row'

export default ({
  data
}) => (
  <DeveloperRow>
    <Name
      className='col-3'
      name={data.name}
    />
    <Param
      className='col-3'
      label='Lines of code on weekends'
      value={data.weekend_pure_additions}
      valueSecond={` (${Math.round(data.weekend_work_share * 100)}% of all lines)`}
    />
    <Param
      className='col-3'
      label='Weeks with working weekends'
      value={`${data.weeks_with_weekend_work} of ${data.all_weeks - data.empty_weeks}`}
      valueSecond={` (${Math.round(data.weeks_with_weekend_work / Math.max(data.all_weeks - data.empty_weeks, 1) * 100)}% of working weeks)`}
    />
    <Param
      className='col-3'
      label='Weeks with overwork on weekends'
      value={`${data.weeks_with_weekend_overwork} of ${data.all_weeks - data.empty_weeks}`}
      valueSecond={` (${Math.round(data.weeks_with_weekend_overwork_share * 100)}% of working weeks)`}
    />
  </DeveloperRow>
)
