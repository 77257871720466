import { firstDayOfMonth } from '../../../../utils/dates'

const dateOffest = (date, sign) =>
  new Date(new Date(date).valueOf() + (10 * 24 * 60 * 60 * 1000) * sign)

export const prepareData = (data, color) => ({
  labels: [
    dateOffest(firstDayOfMonth(data[0].date), -1),
    ...data.map(v => firstDayOfMonth(v.date)),
    dateOffest(firstDayOfMonth(data[data.length - 1].date), +1)
  ],
  datasets: [{
    borderColor: color,
    data: [
      data[0].value,
      ...data.map(v => v.value),
      data[data.length - 1].value
    ],
    fill: color,
    steppedLine: false,
    pointRadius: [0, ...data.map(v => 3), 0],
    datalabels: {
      align: 'end',
      offset: -2,
      anchor: 'end',
      display: [
        false, ...data.map(v => 3), false
      ]
    }
  }]
})

export const prepareOptions = (max, data) => ({
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  hover: false,
  plugins: {
    datalabels: {
      color: 'black',
      font: {
        weight: 'bold',
        size: 16
      },
      formatter: Math.round
    }
  },
  elements: {
    line: {
      tension: 0, // disables bezier curves,
      borderWidth: 2
    },
    point: {
      backgroundColor: 'rgba(255,255,255,1.0)',
      borderWidth: 2
    }
  },
  layout: {
    padding: 0
  },
  title: { display: false },
  legend: { display: false },
  tooltips: { enabled: false },
  scales: {
    xAxes: [{
      display: true,
      type: 'time',
      time: {
        unit: 'month',
        displayFormats: {
          month: 'MMM'
        }
      },
      scaleLabel: {
        display: false
      },
      ticks: {
        display: true,
        fontColor: 'rgba(0,0,0,0.54)',
        padding: 0,
        labelOffset: 0,
        maxRotation: 0,
        source: 'labels',
        callback: function (value, index, values) {
          return (index === 0 || index === values.length - 1)
            ? null
            : `${value} ${new Date(data[index - 1].date).getDate()}`
        }
      },
      gridLines: {
        display: false
      }
    }],
    yAxes: [{
      display: true,
      scaleLabel: { display: false },
      ticks: {
        display: false,
        beginAtZero: true,
        max: Math.max(max + 1, Math.round(max * 1.43)),
        min: -Math.max(max + 1, Math.round(max * 1.43)) / 20
      },
      gridLines: {
        display: false,
        tickMarkLength: 0
      }
    }]
  }
})
