import React from 'react'
import Select from 'react-select'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import qs from 'query-string'

import s from './header.module.css'
import logo from './logo.png'
import config from '../../../../config'

const Header = ({companies, currentCompany, selectCompany, toMain}) => (
  <div className='container-fluid'>
    <div className={classnames('row justify-content-between align-items-center', s.header)}>
      <div className='col'>
        <div className='row justify-content-start align-items-center'>
          <div className='col-auto'>
            <div className={s.logo}>
              <Link to={{pathname: '/', search: qs.stringify({companyId: currentCompany})}}>
                <img src={logo} className={'img-fluid'} alt='GitLean Logo' />
              </Link>
            </div>
          </div>
          <div className='col'>
            <a className={s.oldVersionLink} href={config.oldFrontendLink}>Old version</a>
          </div>
        </div>
      </div>
      <div className='col-auto'>
        <div className='row justify-content-between align-items-center'>
          <div className={classnames('col-auto', s.selectLabel)}>
            Company
          </div>
          <div className='col-auto'>
            <Select
              className={s.select}
              name='currentCompany'
              clearable={false}
              onChange={({id}) => selectCompany(id)}
              placeholder='Select company'
              getOptionLabel={({name}) => name}
              getOptionValue={({id}) => id}
              value={companies.filter(({id}) => id === currentCompany)}
              options={[...companies]}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Header
