import {
  fetchDisciplineHistory,
  fetchLowPerformance,
  fetchOverwork,
  fetchViolations
} from '../../../api'

import {
  OVERVIEW_REQUESTED,
  OVERVIEW,
  LOW_PERFORMANCE_REQUESTED,
  LOW_PERFORMANCE,
  OVERWORK_REQUESTED,
  OVERWORK,
  VIOLATIONS_REQUESTED,
  VIOLATIONS
} from '../constants'
import {actionRequestForCompanyAndTeam} from '../../utils-actions'

export const getOverview = actionRequestForCompanyAndTeam(
  OVERVIEW_REQUESTED,
  OVERVIEW,
  fetchDisciplineHistory
)
export const getLowPerformance = actionRequestForCompanyAndTeam(
  LOW_PERFORMANCE_REQUESTED,
  LOW_PERFORMANCE,
  fetchLowPerformance
)
export const getOverwork = actionRequestForCompanyAndTeam(
  OVERWORK_REQUESTED,
  OVERWORK,
  fetchOverwork
)
export const getViolations = actionRequestForCompanyAndTeam(
  VIOLATIONS_REQUESTED,
  VIOLATIONS,
  fetchViolations
)
