import { push } from 'connected-react-router'
import _ from 'lodash'

export const actionRequestForCompanyAndTeam = (requestedType, successType, apiRequest) => {
  return () => (dispatch, getState) => {
    const state = getState()._global
    const companyId = state.companies.currentCompany
    const teamId = state.companyData.currentTeam

    if (companyId === null) {
      return
    }
    const request = apiRequest(companyId, teamId)
    dispatch({
      type: requestedType,
      payload: { request }
    })

    request.then(data => {
      dispatch({
        type: successType,
        payload: _.isEmpty(data) ? null : { ...data }
      })
    })
  }
}

export const actionToReport = (path) => {
  return () => (dispatch, getState) => {
    const location = getState().router.location
    dispatch(push({
      ...location,
      pathname: path
    }))
  }
}
