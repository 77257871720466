import React from 'react'
import classnames from 'classnames'

import s from './index.module.css'

const LegendItem = ({color, label, className}) => (
  <div className={classnames(className, s.legendItem)}>
    <div className={s.legendColorBox} style={{backgroundColor: color}} />
    <div className={s.legendLabel}>{label}</div>
  </div>
)

const Legend = ({config, className, itemClassName}) => (
  <div className={classnames(className, s.legend)}>
    {config.map(c => (
      <LegendItem key={c.label} color={c.color} label={c.label} className={itemClassName} />
    ))}
  </div>
)

export default Legend
