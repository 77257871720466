import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import ReportChurnByInterval from '../components/report-churn-by-interval'
import {
  getChurnByIntervals
} from '../../../modules/report-time-leaks/actions/requests'

const mapStateToProps = state => ({
  currentTeam: state._global.companyData.currentTeam,
  currentCompany: state._global.companies.currentCompany,

  churnByInterval: _.pick(state.reportTimeLeaks.churnByInterval, ['loading', 'data'])
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getChurnByIntervals
}, dispatch)

class ReportChurnByIntervalContainer extends PureComponent {
  componentDidMount () {
    this.props.getChurnByIntervals()
  }

  componentDidUpdate (prevProps) {
    if (
      this.props.currentCompany !== prevProps.currentCompany ||
      this.props.currentTeam !== prevProps.currentTeam
    ) {
      this.props.getChurnByIntervals()
    }
  }

  render () {
    const { churnByInterval } = this.props
    return <ReportChurnByInterval
      data={churnByInterval.data}
      loading={churnByInterval.loading}
    />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportChurnByIntervalContainer)
