import React from 'react'
import classnames from 'classnames'

import CardWithOverviewChart from '../../../../components/card-with-overview-bar-chart'
import s from './index.module.css'

const chartConfig = [
  {label: 'Too many fixed tasks', name: 'fixed_tasks', color: '#E57373'},
  {label: 'Too many fixed commits', name: 'fixed_commits', color: '#9575CD'}
]
export default ({loading, data, className}) => (
  <CardWithOverviewChart
    className={classnames(className, s.card)}
    loading={loading}
    empty={!data || !data.data || data.data.length === 0}
    chartData={data && data.data}
    chartConfig={chartConfig}
    chartName='Developers with problems'
  />
)
