import React from 'react'

import DeveloperRow, { Param, Name } from '../../../components/report-details-developer-row'

export default ({
  data
}) => (
  <DeveloperRow>
    <Name
      className='col'
      name={data.name}
    />
    <Param
      className='col-3'
      label='Fixed tasks'
      value={`${data.fixed_tasks} of ${data.all_tasks}`}
      valueSecond={` (${Math.round(data.fixed_tasks_share * 100)}%)`}
    />
    <Param
      className='col'
      label='Share of commits with task prefix'
      value={`${Math.round(data.commits_with_tasks * 100)}%`}
    />
    <Param
      className='col'
      label='Avg fixes per task'
      value={`${Math.round(data.avg_fixes_per_fixed_task * 100) / 100}`}
    />
  </DeveloperRow>
)
