import React from 'react'
import Select from 'react-select'
import classnames from 'classnames'

import ReportDates from '../report-dates'
import s from './report-header.module.css'

const ReportHeader = ({
  teams,
  currentTeam,
  selectTeam,
  title,
  maxDate,
  showDates,
  titleStyle
}) => (
  <div className={classnames('row', 'align-items-center', {
    [s.header]: !showDates,
    [s.headerWithDates]: showDates
  })}>
    <div className='col-12'>
      <div className={classnames('row justify-content-between align-items-center')}>
        <div className='col'>
          <div className={classnames({
            [s.titleText]: titleStyle === 'text',
            [s.titleReportName]: titleStyle === 'reportName'
          })}>
            {title}
          </div>
        </div>
        <div className='col-auto'>
          <div className='row justify-content-between align-items-center'>
            <div className={classnames('col-auto', s.selectLabel)}>
              Team
            </div>
            <div className='col-auto'>
              <Select
                className={s.select}
                name='currenTeam'
                clearable={false}
                onChange={({id}) => selectTeam(id > 0 ? id: null)}
                getOptionLabel={({name}) => name}
                getOptionValue={({id}) => id}
                placeholder='Select team'
                value={currentTeam === null ? {id: -1, name: 'All teams'} : teams.filter(v => v.id === currentTeam)}
                options={[{id: -1, name: 'All teams'}, ...teams]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    {showDates ? (
      <div className='col-12'>
        <ReportDates
          lastDate={maxDate}
          days={30}
          type='details'
        />
      </div>
    ) : null}
  </div>
)

export default ReportHeader
