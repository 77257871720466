import qs from 'query-string'
import _ from 'lodash'

import { COMPANIES, COMPANIES_REQUESTED, SELECT_COMPANY } from '../constants'
import { LOCATION_CHANGE } from 'connected-react-router'

const initialState = {
  list: [],
  request: null,
  loading: false,
  currentCompany: null,
  queryCurrentCompany: null
}

const safeCurrent = (list, queryCurrentCompany) =>
  _.get(_.find(list, {id: queryCurrentCompany}), 'id', null) || (list[0] && list[0].id) || null

export default (state = initialState, action) => {
  switch (action.type) {
    case COMPANIES:
      const list = action.payload.companies
      return {
        ...state,
        loading: false,
        request: null,
        list,
        currentCompany: safeCurrent(list, state.queryCurrentCompany)
      }

    case COMPANIES_REQUESTED:
      state.request && state.request.cancel()
      return {
        ...state,
        loading: true,
        list: [],
        currentCompany: null,
        request: action.payload.request
      }

    case SELECT_COMPANY:
      return {
        ...state,
        currentCompany: action.payload.id
      }

    case LOCATION_CHANGE:
      const id = +qs.parse(action.payload.location.search).companyId || null
      return {
        ...state,
        currentCompany: id !== state.currentCompany ? null : state.currentCompany,
        queryCurrentCompany: id
      }

    default:
      return state
  }
}
