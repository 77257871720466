import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import _global from './_global/reducers'
import reportMain from './report-main/reducers'
import reportDiscipline from './report-discipline/reducers'
import reportQualityRisks from './report-quality-risks/reducers'
import reportTimeLeaks from './report-time-leaks/reducers'
import reportBusFactor from './report-bus-factor/reducers'

export default (history) => combineReducers({
  router: connectRouter(history),
  _global,
  reportMain,
  reportDiscipline,
  reportQualityRisks,
  reportTimeLeaks,
  reportBusFactor
})
