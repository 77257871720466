import React from 'react'
import classnames from 'classnames'

import CardWithOverviewChart from '../../../../components/card-with-overview-bar-chart'
import s from './index.module.css'

const chartConfig = [
  {label: 'Violations', name: 'violations', color: '#7986cb'},
  {label: 'Overwork', name: 'overwork', color: '#ff80ab'},
  {label: 'Low performance', name: 'low_performance', color: '#ffe57f'}
]
export default ({loading, data, className}) => (
  <CardWithOverviewChart
    className={classnames(className, s.card)}
    loading={loading}
    empty={!data || !data.data || data.data.length === 0}
    chartData={data && data.data}
    chartConfig={chartConfig}
    chartName='Developers with problems'
  />
)
