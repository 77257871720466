import React from 'react'
import { Route } from 'react-router-dom'

const Report = ({routes}) => (
  <div>
    {routes.map((route, i) => <Route key={i} {...route} />)}
  </div>
)

export default Report
