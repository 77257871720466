import {
  fetchQualityRisksHistory,
  fetchTooManyFixedTasks,
  fetchTooManyFixedCommits
} from '../../../api'

import {
  OVERVIEW_REQUESTED,
  OVERVIEW,
  TOO_MANY_FIXED_TASKS_REQUESTED,
  TOO_MANY_FIXED_TASKS,
  TOO_MANY_FIXED_COMMITS_REQUESTED,
  TOO_MANY_FIXED_COMMITS
} from '../constants'
import {actionRequestForCompanyAndTeam} from '../../utils-actions'

export const getOverview = actionRequestForCompanyAndTeam(
  OVERVIEW_REQUESTED,
  OVERVIEW,
  fetchQualityRisksHistory
)
export const getTooManyFixedTasks = actionRequestForCompanyAndTeam(
  TOO_MANY_FIXED_TASKS_REQUESTED,
  TOO_MANY_FIXED_TASKS,
  fetchTooManyFixedTasks
)
export const getTooManyFixedCommits = actionRequestForCompanyAndTeam(
  TOO_MANY_FIXED_COMMITS_REQUESTED,
  TOO_MANY_FIXED_COMMITS,
  fetchTooManyFixedCommits
)
