import React from 'react'
import classnames from 'classnames'

import s from './index.module.css'

const MainText = ({children, label}) => (
  <div className={classnames('row', s.cardText)}>
    <div className='col'>
      <div className='row justify-content-center'>
        <div className='col text-center'>
          {children}
        </div>
      </div>
      <div className='row justify-content-center'>
        <div className='col text-center'>
          <span className={classnames(s.secondaryText, 'align-middle')}>{label}</span>
        </div>
      </div>
    </div>
  </div>
)

export default MainText
