import React from 'react'
import classnames from 'classnames'

import CardWithTitle from '../card-with-title'
import s from './index.module.css'
import EmptyGroup from './empty-group'
import DeveloperRow from './developer-row'

const calcLimitsForGroups = (groups, data) => {
  const counts = groups.map(g => ({
    count: (data && data[g.name] && data[g.name].length) || 2, // 2 - size of empty text
    limit: g.limit
  }))
  let availableLimit = counts.map(c => Math.max(c.limit - c.count, 0)).reduce((a, v) => a + v, 0)
  const limits = counts.map(c => {
    if (c.count <= c.limit || availableLimit === 0) {
      return c
    }
    const l = {...c}
    if (l.count - l.limit > availableLimit) {
      l.limit += availableLimit
    } else {
      availableLimit -= (l.count - l.limit)
      l.limit = l.count
    }
    return l
  })
  const more = limits.map(c => Math.max(c.count - c.limit, 0)).reduce((a, v) => a + v, 0)
  return {
    groupsWithLimits: groups.map((g, i) => ({...g, limit: limits[i].limit})),
    more
  }
}

const Group = ({groups, data, label, limit, element, emptyText}) => (
  <div className='col-12'>
    <div className={classnames('row', s.groupTitle)}>
      <div className='col'>
        {label}
      </div>
    </div>
    {!data || data.length === 0
      ? <div className={s.empty}><EmptyGroup text={emptyText} /></div>
      : data.slice(0, limit).map(d => React.createElement(element, {key: d.id, data: d}))
    }
  </div>
)

export default ({
  loading,
  empty,
  title,
  hint,
  withHover,
  className,
  onClick,
  data,
  groups // Array<{label, limit, name, element, emptyText}>
}) => {
  const {groupsWithLimits, more} = calcLimitsForGroups(groups, data)
  return (
    <CardWithTitle
      loading={loading}
      Text={empty}
      title={title}
      hint={hint}
      withHover={withHover}
      className={className}
      innerClassName={s.card}
      onClick={onClick}
    >
      <div className={classnames('row', s.body)}>
        {groupsWithLimits.map(g =>
          <Group
            key={g.label}
            label={g.label}
            data={data && data[g.name]}
            groups={groups}
            limit={g.limit}
            element={g.element}
            emptyText={g.emptyText}
          />
        )}
        {more > 0
          ? <div className={classnames('col-12', s.more)}>+ {more} more...</div>
          : null
        }
      </div>
    </CardWithTitle>
  )
}

export { DeveloperRow, EmptyGroup }
