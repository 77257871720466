import React from 'react'

import Category from '../../../components/report-details-category'
import Developer from './developer'
import Loader from '../../../components/loader'
import ReportHeader from '../../../containers/report-header'

const ReportBusFactor = ({
  data,
  loading
}) => (
  <div className='container'>
    <ReportHeader title={'Unique developers'} titleStyle='reportName' showDates baseReportName='busFactor' />
    {loading
      ? <Loader centered size='lg' />
      : (
        <div className='row'>
          <div className='col-12'>
            <Category
              title='Very unique developers'
              emptyText='There are no developers with such problem'
            >
              {data && data.very_bad && data.very_bad.map(d =>
                <Developer key={d.id} data={d} />
              )}
            </Category>
          </div>
          <div className='col-12'>
            <Category
              title='Unique developers'
              emptyText='There are no developers with such problem'
            >
              {data && data.bad && data.bad.map(d =>
                <Developer key={d.id} data={d} />
              )}
            </Category>
          </div>
          <div className='col-12'>
            <Category
              title='Not unique developers'
              emptyText='There are no developers normal or low uniqueness'
            >
              {data && data.others && data.others.map(d =>
                <Developer key={d.id} data={d} />
              )}
            </Category>
          </div>
        </div>
      )
    }
  </div>
)

export default ReportBusFactor
