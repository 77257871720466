import React from 'react'
import s from './empty.module.css'

export default () => (
  <div className='container'>
    <div className='row justify-content-center'>
      <div className='col-auto'>
        <div className={s.text}>
          There is no data for choosen company
        </div>
      </div>
    </div>
  </div>
)
