import React from 'react'

import DeveloperRow, { Param, Name } from '../../../components/report-details-developer-row'

export default ({
  data
}) => (
  <DeveloperRow>
    <Name
      className='col'
      name={data.name}
    />
    <Param
      className='col-3'
      label='Fixed commits'
      value={`${data.fixed_commits} of ${data.all_commits}`}
      valueSecond={` (${data.all_commits === 0 ? 'No merged code' : Math.round(data.fixed_commits_share * 100) + '%'})`}
    />
    <Param
      className='col'
      label='Fixed lines of code'
      value={`${data.fixed_lines} of ${data.all_lines}`}
      valueSecond={` (${data.all_commits === 0 ? 'No merged code' : Math.round(data.fixed_lines_share * 100) + '%'})`}
    />
    <Param
      className='col'
      label='Avg fixes per commit'
      value={`${Math.round(data.avg_fixes_per_fixed_commit * 100) / 100}`}
    />
  </DeveloperRow>
)
