import { combineReducers } from 'redux'

import {
  TOO_UNIQUE_REQUESTED,
  TOO_UNIQUE
} from '../constants'
import { requestReducer } from '../../utils-reducers'

export default combineReducers({
  tooUnique: requestReducer(TOO_UNIQUE_REQUESTED, TOO_UNIQUE)
})
