import React from 'react'
import ReportHeader from '../../../containers/report-header'
import ReportDates from '../../../components/report-dates'

import CardOverview from './card-overview'
import {
  FixedTasks,
  FixedCommits
} from './card-list'

const ReportQualityRisks = ({
  maxReportDate,
  overview,
  tooManyFixedTasks,
  toTooManyFixedTasks,
  tooManyFixedCommits,
  toTooManyFixedCommits
}) => (
  <div className='container'>
    <ReportHeader title={'Quality risks'} titleStyle='reportName' />
    <div className='row'>
      <CardOverview
        className='col'
        loading={overview.loading}
        data={overview.data}
      />
    </div>
    <div className='row'>
      <ReportDates
        className='col'
        lastDate={maxReportDate}
        days={30}
        type='report'
      />
    </div>
    <div className='row'>
      <FixedTasks
        className='col-4'
        data={tooManyFixedTasks.data}
        loading={tooManyFixedTasks.loading}
        onClick={toTooManyFixedTasks}
      />
      <FixedCommits
        className='col-4'
        data={tooManyFixedCommits.data}
        loading={tooManyFixedCommits.loading}
        onClick={toTooManyFixedCommits}
      />
    </div>
  </div>
)

export default ReportQualityRisks
