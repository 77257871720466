import React from 'react'
import classnames from 'classnames'

import CardWithGroupedLists, { DeveloperRow } from '../../../../components/card-with-grouped-lists'
import hints from '../../../../strings/hints'
import s from './index.module.css'

const churnByTasksGroups = [{
  name: 'high_churn',
  label: 'High churn',
  limit: 7,
  element: ({data}) => (
    <DeveloperRow name={data.name} value={`${data.leaked_hours}h`} />
  ),
  emptyText: 'There are no developers with such problem'
}]
export const ChurnByTasks = ({data, loading, onClick, className}) => (
  <CardWithGroupedLists
    title='Churn of code in tasks'
    hint={hints.churnByTasks}
    loading={loading}
    empty={!data}
    withHover
    className={classnames(className, s.card)}
    data={data}
    onClick={onClick}
    groups={churnByTasksGroups}
  />
)

const churnByIntervalGroups = [{
  name: 'high_churn',
  label: 'High churn',
  limit: 7,
  element: ({data}) => (
    <DeveloperRow name={data.name} value={`${data.leaked_hours}h`} />
  ),
  emptyText: 'There are no developers with such problem'
}]
export const ChurnByInterval = ({data, loading, onClick, className}) => (
  <CardWithGroupedLists
    title='Churn of all code'
    hint={hints.churnByIntervals}
    loading={loading}
    empty={!data}
    withHover
    className={classnames(className, s.card)}
    data={data}
    onClick={onClick}
    groups={churnByIntervalGroups}
  />
)
