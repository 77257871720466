import React, { Component } from 'react'
import classnames from 'classnames'
import Chart from 'react-chartjs-2'
import _ from 'lodash'

import Loader from '../loader'
import s from './index.module.css'

const emptyState = () => (
  <div className={classnames('row', 'justify-content-center', 'align-items-center', 'h-100', s.empty)}>
    <div className='col-auto'>
      <b>No data</b>
    </div>
  </div>
)

const loader = () => (
  <div className={classnames('row', 'justify-content-center', 'align-items-center', 'h-100', s.loader)}>
    <div className='col-auto'>
      <Loader />
    </div>
  </div>
)

export default class CardWithChartOnly extends Component {
  shouldComponentUpdate (nextProps, nextState) {
    return !_.isEqual(this.props, nextProps)
  }

  render () {
    const {
      loading,
      empty,
      withHover,
      className,
      chartType,
      chartData,
      chartOptions,
      onClick,
      legend
    } = this.props
    return (
      <div className={classnames(className)}>
        <div
          className={classnames('h-100', s.card, {[s.cardWithHover]: withHover})}
          onClick={() => onClick && onClick()}
        >
          <div className={classnames('row', 'h-100', s.body)}>
            {!loading && !empty ? <div className='col-12'>{legend}</div> : null}
            <div className='col-12'>
              {loading
                ? loader()
                : empty
                  ? emptyState()
                  : <Chart type={chartType} data={_.cloneDeep(chartData)} options={_.cloneDeep(chartOptions)} />
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
