import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import ReportChurnByTasks from '../components/report-churn-by-tasks'
import {
  getChurnByTasks
} from '../../../modules/report-time-leaks/actions/requests'

const mapStateToProps = state => ({
  currentTeam: state._global.companyData.currentTeam,
  currentCompany: state._global.companies.currentCompany,

  churnByTasks: _.pick(state.reportTimeLeaks.churnByTasks, ['loading', 'data'])
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getChurnByTasks
}, dispatch)

class ReportChurnByTasksContainer extends PureComponent {
  componentDidMount () {
    this.props.getChurnByTasks()
  }

  componentDidUpdate (prevProps) {
    if (
      this.props.currentCompany !== prevProps.currentCompany ||
      this.props.currentTeam !== prevProps.currentTeam
    ) {
      this.props.getChurnByTasks()
    }
  }

  render () {
    const { churnByTasks } = this.props
    return <ReportChurnByTasks
      data={churnByTasks.data}
      loading={churnByTasks.loading}
    />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportChurnByTasksContainer)
