import React from 'react'

import DeveloperRow, { Param, Name } from '../../../components/report-details-developer-row'

export default ({
  data
}) => (
  <DeveloperRow>
    <Name
      className='col-3'
      name={data.name}
    />
    <Param
      className='col'
      label='Share of commits without task prefix'
      value={`${Math.round((1 - data.commits_with_task_share) * 100)}%`}
    />
    <Param
      className='col'
      label='Commits without task task prefix'
      value={`${data.all_commits - data.commits_with_task}`}
    />
    <Param
      className='col-2'
      label='All commits'
      value={data.all_commits}
    />
  </DeveloperRow>
)
