import React, { Component } from 'react'
import _ from 'lodash'
import classnames from 'classnames'

import CardWithChart from '../card-with-chart-only'
import {prepareData, prepareOptions} from './prepare-for-chart'
import s from './index.module.css'
import Legend from './legend'

export default class CardWithOverviewChart extends Component {
  shouldComponentUpdate (nextProps, nextState) {
    return !_.isEqual(this.props, nextProps)
  }

  renderLegend () {
    const { chartConfig, chartName } = this.props
    return (
      <div className={classnames('row align-items-center', s.legendContainer)}>
        <div className={classnames('col-auto', s.chartAxisLabel)}>
          {chartName}
        </div>
        <div className='col'>
          <Legend
            config={chartConfig}
            className='row justify-content-md-center'
            itemClassName='col-auto'
          />
        </div>
      </div>
    )
  }

  render () {
    const {
      loading,
      empty,
      className,
      chartData,
      chartConfig
    } = this.props

    return (
      <CardWithChart
        loading={loading}
        empty={empty}
        withHover={false}
        legend={this.renderLegend()}
        className={className}
        chartType='bar'
        chartData={prepareData(chartData, chartConfig)}
        chartOptions={prepareOptions(chartData, chartConfig)}
      />
    )
  }
}
