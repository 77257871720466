import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import ReportFixedTasks from '../components/report-fixed-tasks'
import {
  getTooManyFixedTasks
} from '../../../modules/report-quality-risks/actions/requests'

const mapStateToProps = state => ({
  currentTeam: state._global.companyData.currentTeam,
  currentCompany: state._global.companies.currentCompany,

  tooManyFixedTasks: _.pick(state.reportQualityRisks.tooManyFixedTasks, ['loading', 'data'])
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getTooManyFixedTasks
}, dispatch)

class ReportFixedTasksContainer extends PureComponent {
  componentDidMount () {
    this.props.getTooManyFixedTasks()
  }

  componentDidUpdate (prevProps) {
    if (
      this.props.currentCompany !== prevProps.currentCompany ||
      this.props.currentTeam !== prevProps.currentTeam
    ) {
      this.props.getTooManyFixedTasks()
    }
  }

  render () {
    const { tooManyFixedTasks } = this.props
    return <ReportFixedTasks
      data={tooManyFixedTasks.data}
      loading={tooManyFixedTasks.loading}
    />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportFixedTasksContainer)
