import { combineReducers } from 'redux'

import {
  OVERVIEW_REQUESTED,
  OVERVIEW,
  TOO_MANY_FIXED_TASKS_REQUESTED,
  TOO_MANY_FIXED_TASKS,
  TOO_MANY_FIXED_COMMITS_REQUESTED,
  TOO_MANY_FIXED_COMMITS
} from '../constants'
import { requestReducer } from '../../utils-reducers'

export default combineReducers({
  overview: requestReducer(OVERVIEW_REQUESTED, OVERVIEW),
  tooManyFixedTasks: requestReducer(TOO_MANY_FIXED_TASKS_REQUESTED, TOO_MANY_FIXED_TASKS),
  tooManyFixedCommits: requestReducer(TOO_MANY_FIXED_COMMITS_REQUESTED, TOO_MANY_FIXED_COMMITS)
})
