import _ from 'lodash'

import { firstDayOfMonth } from '../../utils/dates'

export const prepareData = (data, config) => (!data ? null : {
  labels: data.map(v => firstDayOfMonth(v.date)),
  datasets: config.map(c => ({
    backgroundColor: c.color,
    data: data.map(v => v[c.name]),
    label: c.label,
    stack: 1,
    maxBarThickness: 80,
    categoryPercentage: 0.5,
  }))
})

const maxValue = (data, config) => _.max(data.map(d => _.sum(_.map(config, c => d[c.name]))))

export const prepareOptions = (data, config) => (!data ? null : {
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  hover: true,
  plugins: {
    datalabels: false
  },
  layout: {
    padding: 0
  },
  title: { display: false },
  legend: { display: false },
  tooltips: { enabled: true },
  scales: {
    xAxes: [{
      display: true,
      offset: true,
      type: 'time',
      time: {
        unit: 'month',
        displayFormats: {
          month: 'MMM'
        }
      },
      scaleLabel: {
        display: false
      },
      ticks: {
        display: true,
        fontColor: 'rgba(0,0,0,0.54)',
        padding: 0,
        labelOffset: 0,
        maxRotation: 0,
        source: 'labels',
        callback: (value, index, values) =>
          `${value} ${new Date(data[index].date).getDate()}`
      },
      gridLines: {
        display: true,
        drawOnChartArea: false,
        tickMarkLength: 8,
      }
    }],
    yAxes: [{
      display: true,
      stacked: true,
      scaleLabel: { display: false },
      ticks: {
        display: true,
        fontColor: 'rgba(0,0,0,0.54)',
        beginAtZero: true,
        padding: 5,
        max: maxValue(data, config) * 1.1,
        callback: (value, index, values) =>
          index === 0 || index === values.length - 1 || Math.floor(value) !== value ? null : value
      },
      gridLines: {
        display: true,
        tickMarkLength: 8,
        drawOnChartArea: false,
        zeroLineColor: 'rgba(0,0,0,0)'
      }
    }]
  }
})
