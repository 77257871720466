import {
  fetchDisciplineOverview,
  fetchQualityRisksOverview,
  fetchTimeLeasksOverview,
  fetchBusFactorOverview
} from '../../../api'

import {
  DISCIPLINE_REQUESTED,
  DISCIPLINE,
  BUS_FACTOR_REQUESTED,
  BUS_FACTOR,
  QUALITY_RISKS_REQUESTED,
  QUALITY_RISKS,
  TIME_LEAKS_REQUESTED,
  TIME_LEAKS
} from '../constants'
import {actionRequestForCompanyAndTeam} from '../../utils-actions'

export const getDiscipline = actionRequestForCompanyAndTeam(
  DISCIPLINE_REQUESTED,
  DISCIPLINE,
  fetchDisciplineOverview
)
export const getBusFactor = actionRequestForCompanyAndTeam(
  BUS_FACTOR_REQUESTED,
  BUS_FACTOR,
  fetchBusFactorOverview
)
export const getQualityRisks = actionRequestForCompanyAndTeam(
  QUALITY_RISKS_REQUESTED,
  QUALITY_RISKS,
  fetchQualityRisksOverview
)
export const getTimeLeaks = actionRequestForCompanyAndTeam(
  TIME_LEAKS_REQUESTED,
  TIME_LEAKS,
  fetchTimeLeasksOverview
)
