import fetch from 'cross-fetch'
import Promise from 'bluebird'
import _ from 'lodash'

import config from '../config'

Promise.config({cancellation: true})
export const jsonPost = (path, body) =>
  Promise.resolve(fetch(
    `${config.apiRoot}/${path}`,
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(body)
    }).then(res => res.json())
  )
export const jsonGet = (path) =>
  Promise.resolve(fetch(`${config.apiRoot}/${path}`).then(res => res.json()))

export const regroupReportHistoryResponse = res => ({
  data: _.uniq(_.flatten(_.keys(res).map(k => res[k].map(r => r.date)))).map(d => ({
    date: d,
    ..._.mapValues(res, v => +(_.find(v, {date: d}) || {value: 0}).value)
  }))
})
