import { push, replace } from 'connected-react-router'
import qs from 'query-string'

import { fetchCompanyData } from '../../../api'
import { COMPANY_DATA, COMPANY_DATA_REQUESTED, SELECT_TEAM } from '../constants'

export const getCompanyData = (companyId) => {
  return (dispatch, getState) => {
    if (companyId === null) {
      return
    }
    const request = fetchCompanyData(companyId)
    dispatch({
      type: COMPANY_DATA_REQUESTED,
      payload: { request }
    })

    request.then(data => {
      dispatch({
        type: COMPANY_DATA,
        payload: data
      })
    })
  }
}

export const selectTeam = (teamId, type) => {
  return (dispatch, getState) => {
    const location = getState().router.location
    dispatch((type === 'replace' ? replace : push)({
      ...location,
      search: qs.stringify({...qs.parse(location.search), teamId})
    }))
    dispatch({
      type: SELECT_TEAM,
      payload: { id: teamId }
    })
  }
}
