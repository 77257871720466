import React from 'react'

import Category from '../../../components/report-details-category'
import Developer from './developer'
import Loader from '../../../components/loader'
import ReportHeader from '../../../containers/report-header'

const ReportLowPerfromance = ({
  data,
  loading
}) => (
  <div className='container'>
    <ReportHeader title={'Overwork'} titleStyle='reportName' showDates baseReportName='discipline' />
    {loading
      ? <Loader centered size='lg' />
      : (
        <div className='row'>
          <div className='col-12'>
            <Category
              title='Too much code on weekends'
              emptyText='There are no developers with such problem'
            >
              {data && data.too_much_weekend_work && data.too_much_weekend_work.map(d =>
                <Developer key={d.id} data={d} />
              )}
            </Category>
          </div>
        </div>
      )
    }
  </div>
)

export default ReportLowPerfromance
