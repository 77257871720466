import React from 'react'
import classnames from 'classnames'

import CardWithGroupedLists, { DeveloperRow } from '../../../../components/card-with-grouped-lists'
import hints from '../../../../strings/hints'
import s from './index.module.css'

const lowPerformanceGroups = [{
  name: 'not_enough_code',
  label: 'Not enough code',
  limit: 3,
  element: ({data}) => (
    <DeveloperRow name={data.name} value={data.additions} />
  ),
  emptyText: 'There are no developers with such problem'
}, {
  name: 'not_enough_productive_days',
  label: 'Not enough productive days',
  limit: 3,
  element: ({data}) => (
    <DeveloperRow name={data.name} value={`${data.productive_days}/${data.business_days}`} />
  ),
  emptyText: 'There are no developers with such problem'
}]
export const LowPerformance = ({data, loading, onClick, className}) => (
  <CardWithGroupedLists
    title='Low performace'
    hint={hints.lowPerformance}
    loading={loading}
    empty={!data}
    withHover
    className={classnames(className, s.card)}
    data={data}
    onClick={onClick}
    groups={lowPerformanceGroups}
  />
)

const overworkGroups = [{
  name: 'too_much_weekend_work',
  label: 'Too much code on weekends',
  limit: 7,
  element: ({data}) => (
    <DeveloperRow name={data.name} value={data.weekend_pure_additions} />
  ),
  emptyText: 'There are no developers with such problem'
}]
export const Overwork = ({data, loading, onClick, className}) => (
  <CardWithGroupedLists
    title='Overwork'
    hint={hints.overwork}
    loading={loading}
    empty={!data}
    withHover
    className={classnames(className, s.card)}
    data={data}
    onClick={onClick}
    groups={overworkGroups}
  />
)

const violationsGroups = [{
  name: 'commits_without_task_prefix',
  label: 'Commits without task prefix',
  limit: 7,
  element: ({data}) => (
    <DeveloperRow name={data.name} value={`${data.all_commits - data.commits_with_task}/${data.all_commits}`} />
  ),
  emptyText: 'There are no developers with such problem'
}]
export const Violations = ({data, loading, onClick, className}) => (
  <CardWithGroupedLists
    title='Violations'
    hint={hints.violations}
    loading={loading}
    empty={!data}
    withHover
    className={classnames(className, s.card)}
    data={data}
    onClick={onClick}
    groups={violationsGroups}
  />
)
