import _ from 'lodash'

import {
  jsonPost
} from './utils'

export function fetchTimeLeasksOverview (companyId, teamId) {
  return jsonPost(
    `dashboard/timeLeaks/timeLeaksOverview`,
    {companyId, teamId}
  ).then(data => ({
    ...data,
    developers_with_time_leaks: data.developers_with_time_leaks.filter(d => d.too_big_churn)
  }))
}

export function fetchTimeLeaksHistory (companyId, teamId) {
  return jsonPost(
    `dashboard/timeLeaks/timeLeaksHistory`,
    {companyId, teamId}
  ).then(res => ({
    data: _.uniq(_.flatten(_.keys(res).map(k => res[k].map(r => r.date)))).map(d => ({
      date: d,
      ..._.mapValues(res, v => +(_.find(v, {date: d}) || {leaked_hours: 0}).leaked_hours),
      effective_hours: _.sum(_.map(res, v => {
        const forDate = _.find(v, {date: d})
        return forDate ? forDate.active_hours - forDate.leaked_hours : 0
      }))
    }))
  }))
}

export function fetchChurnByTasks (companyId, teamId) {
  return jsonPost(
    `dashboard/timeLeaks/churnByTasks`,
    {companyId, teamId}
  ).then(data => ({
    high_churn: _.orderBy(data.churn.filter(d => d.too_big_churn), 'leaked_hours', 'desc'),
    low_churn: _.orderBy(data.churn.filter(d => !d.too_big_churn), 'leaked_hours', 'desc')
  }))
}

export function fetchChurnByIntervals (companyId, teamId) {
  return jsonPost(
    `dashboard/timeLeaks/churnByIntervals`,
    {companyId, teamId}
  ).then(data => ({
    high_churn: _.orderBy(data.churn.filter(d => d.too_big_churn), 'leaked_hours', 'desc'),
    low_churn: _.orderBy(data.churn.filter(d => !d.too_big_churn), 'leaked_hours', 'desc')
  }))
}
