import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Report from '../components/report'
import Empty from '../components/empty'
import Loader from '../../../components/loader'
import { getCompanyData } from '../../../modules/_global/actions/company_data'

const mapStateToProps = state => ({
  currentCompany: state._global.companies.currentCompany,
  loading: state._global.companyData.loading
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getCompanyData
}, dispatch)

class ReportContainer extends PureComponent {
  componentDidMount () {
    this.props.getCompanyData(this.props.currentCompany)
  }

  componentDidUpdate (prevProps) {
    if (this.props.currentCompany !== prevProps.currentCompany) {
      this.props.getCompanyData(this.props.currentCompany)
    }
  }

  render () {
    const { loading, currentCompany, routes } = this.props
    if (currentCompany === null) {
      return <Empty />
    } else if (loading) {
      return <Loader centered size='lg' />
    } else {
      return <Report routes={routes} />
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportContainer))
