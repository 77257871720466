import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import ReportDiscipline from '../components/report-discipline'
import {
  getOverview,
  getLowPerformance,
  getOverwork,
  getViolations
} from '../../../modules/report-discipline/actions/requests'
import {
  toLowPerformance,
  toOverwork,
  toViolations
} from '../../../modules/report-discipline/actions/routing'

const mapStateToProps = state => ({
  currentTeam: state._global.companyData.currentTeam,
  currentCompany: state._global.companies.currentCompany,

  maxReportDate: state._global.companyData.maxReportsDates.discipline,

  overview: _.pick(state.reportDiscipline.overview, ['loading', 'data']),
  lowPerformance: _.pick(state.reportDiscipline.lowPerformance, ['loading', 'data']),
  overwork: _.pick(state.reportDiscipline.overwork, ['loading', 'data']),
  violations: _.pick(state.reportDiscipline.violations, ['loading', 'data'])
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getOverview,
  getLowPerformance,
  getOverwork,
  getViolations,

  toLowPerformance,
  toOverwork,
  toViolations
}, dispatch)

class ReportDisciplineContainer extends PureComponent {
  componentDidMount () {
    this.props.getOverview()
    this.props.getLowPerformance()
    this.props.getOverwork()
    this.props.getViolations()
  }

  componentDidUpdate (prevProps) {
    if (
      this.props.currentCompany !== prevProps.currentCompany ||
      this.props.currentTeam !== prevProps.currentTeam
    ) {
      this.props.getOverview()
      this.props.getLowPerformance()
      this.props.getOverwork()
      this.props.getViolations()
    }
  }

  render () {
    const {
      overview, lowPerformance, overwork, violations,
      toLowPerformance, toOverwork, toViolations,
      maxReportDate
    } = this.props
    return <ReportDiscipline
      maxReportDate={maxReportDate}
      overview={overview}
      lowPerformance={lowPerformance}
      toLowPerformance={toLowPerformance}
      overwork={overwork}
      toOverwork={toOverwork}
      violations={violations}
      toViolations={toViolations}
    />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportDisciplineContainer)
