import React from 'react'
import ReportHeader from '../../../containers/report-header'
import ReportDates from '../../../components/report-dates'

import CardOverview from './card-overview'
import {
  ChurnByTasks,
  ChurnByInterval
} from './card-list'

const ReportTimeLeaks = ({
  maxReportDate,
  overview,
  churnByTasks,
  toChurnByTasks,
  churnByInterval,
  toChurnByInterval
}) => (
  <div className='container'>
    <ReportHeader title={'Time leaks'} titleStyle='reportName' />
    <div className='row'>
      <CardOverview
        className='col'
        loading={overview.loading}
        data={overview.data}
      />
    </div>
    <div className='row'>
      <ReportDates
        className='col'
        lastDate={maxReportDate}
        days={30}
        type='report'
      />
    </div>
    <div className='row'>
      <ChurnByTasks
        className='col-4'
        data={churnByTasks.data}
        loading={churnByTasks.loading}
        onClick={toChurnByTasks}
      />
      <ChurnByInterval
        className='col-4'
        data={churnByInterval.data}
        loading={churnByInterval.loading}
        onClick={toChurnByInterval}
      />
    </div>
  </div>
)

export default ReportTimeLeaks
