import React from 'react'

import DeveloperRow, { Param, Name } from '../../../components/report-details-developer-row'

export default ({
  data
}) => (
  <DeveloperRow>
    <Name
      className='col'
      name={data.name}
    />
    <Param
      className='col-3'
      label='Lines of code'
      value={data.additions}
      valueHelp=' (min threshold 600)'
    />
    <Param
      className='col'
      label='Productive days'
      value={`${data.productive_days} of ${data.business_days}`}
      valueSecond={` (${Math.round(data.productive_days / data.business_days * 100)}%)`}
    />
    <Param
      className='col'
      label='Unproductive days'
      value={`${data.unproductive_days} of ${data.business_days}`}
      valueSecond={` (${Math.round(data.unproductive_days / data.business_days * 100)}%)`}
    />
    <Param
      className='col'
      label='Inactive days'
      value={`${data.inactive_days} of ${data.business_days}`}
      valueSecond={` (${Math.round(data.inactive_days / data.business_days * 100)}%)`}
    />
  </DeveloperRow>
)
