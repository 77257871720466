import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import ReportTimeLeaks from '../components/report-time-leaks'
import {
  getOverview,
  getChurnByTasks,
  getChurnByIntervals
} from '../../../modules/report-time-leaks/actions/requests'
import {
  toChurnByTasks,
  toChurnByInterval
} from '../../../modules/report-time-leaks/actions/routing'

const mapStateToProps = state => ({
  currentTeam: state._global.companyData.currentTeam,
  currentCompany: state._global.companies.currentCompany,

  maxReportDate: state._global.companyData.maxReportsDates.timeLeaks,

  overview: _.pick(state.reportTimeLeaks.overview, ['loading', 'data']),
  churnByTasks: _.pick(state.reportTimeLeaks.churnByTasks, ['loading', 'data']),
  churnByInterval: _.pick(state.reportTimeLeaks.churnByInterval, ['loading', 'data'])
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getOverview,
  getChurnByTasks,
  getChurnByIntervals,

  toChurnByTasks,
  toChurnByInterval
}, dispatch)

class ReportTimeLeaksContainer extends PureComponent {
  componentDidMount () {
    this.props.getOverview()
    this.props.getChurnByTasks()
    this.props.getChurnByIntervals()
  }

  componentDidUpdate (prevProps) {
    if (
      this.props.currentCompany !== prevProps.currentCompany ||
      this.props.currentTeam !== prevProps.currentTeam
    ) {
      this.props.getOverview()
      this.props.getChurnByTasks()
      this.props.getChurnByIntervals()
    }
  }

  render () {
    const {
      overview,
      maxReportDate,
      churnByTasks, toChurnByTasks,
      churnByInterval, toChurnByInterval
    } = this.props
    return <ReportTimeLeaks
      overview={overview}
      maxReportDate={maxReportDate}

      churnByTasks={churnByTasks}
      toChurnByTasks={toChurnByTasks}

      churnByInterval={churnByInterval}
      toChurnByInterval={toChurnByInterval}
    />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportTimeLeaksContainer)
