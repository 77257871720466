import { combineReducers } from 'redux'

import {
  DISCIPLINE_REQUESTED,
  DISCIPLINE,
  BUS_FACTOR_REQUESTED,
  BUS_FACTOR,
  QUALITY_RISKS_REQUESTED,
  QUALITY_RISKS,
  TIME_LEAKS_REQUESTED,
  TIME_LEAKS
} from '../constants'
import { requestReducer } from '../../utils-reducers'

export default combineReducers({
  discipline: requestReducer(DISCIPLINE_REQUESTED, DISCIPLINE),
  busFactor: requestReducer(BUS_FACTOR_REQUESTED, BUS_FACTOR),
  qualityRisks: requestReducer(QUALITY_RISKS_REQUESTED, QUALITY_RISKS),
  timeLeaks: requestReducer(TIME_LEAKS_REQUESTED, TIME_LEAKS)
})
