import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import ReportHeader from '../components/report-header'
import {selectTeam} from '../modules/_global/actions/company_data'

const mapStateToProps = state => ({
  teams: state._global.companyData.teams,

  queryCurrentTeam: state._global.companyData.queryCurrentTeam,
  currentTeam: state._global.companyData.currentTeam,

  maxCompanyDate: state._global.companyData.maxCompanyDate,
  maxReportsDates: state._global.companyData.maxReportsDates
})

const mapDispatchToProps = dispatch => bindActionCreators({
  selectTeam
}, dispatch)

class ReportHeaderContainer extends PureComponent {
  componentDidMount () {
    if (this.props.queryCurrentTeam !== this.props.currentTeam) {
      this.props.selectTeam(null, 'replace')
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.queryCurrentTeam !== this.props.currentTeam) {
      this.props.selectTeam(null, 'replace')
    }
  }

  render () {
    const {
      currentTeam,
      teams,
      selectTeam,
      title,
      maxCompanyDate,
      maxReportsDates,
      showDates,
      baseReportName,
      titleStyle
    } = this.props

    const maxDate = maxReportsDates[baseReportName] || maxCompanyDate

    return <ReportHeader
      teams={teams}
      selectTeam={id => id !== currentTeam && selectTeam(id)}
      currentTeam={currentTeam}
      title={title}
      maxDate={maxDate}
      titleStyle={titleStyle}
      showDates={showDates}
    />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportHeaderContainer)
