import React from 'react'
import Header from './header'
import Breadcrumbs from './breadcrumbs'
import Report from '../containers/report'

const App = ({routes, location, companies, currentCompany, selectCompany, toMain}) => (
  <div>
    <Header companies={companies} currentCompany={currentCompany} selectCompany={selectCompany} toMain={toMain} />
    <Breadcrumbs routes={routes} location={location} />
    <Report routes={routes} />
  </div>
)

export default App
