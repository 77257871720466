import React from 'react'
import classnames from 'classnames'

const EmptyGroup = ({text, className}) => (
  <div className={classnames('row justify-content-center align-items-center', className)}>
    <div className='col-8'>
      {text}
    </div>
  </div>
)
export default EmptyGroup
