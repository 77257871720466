import React from 'react'

import Category from '../../../components/report-details-category'
import Developer from './developer'
import Loader from '../../../components/loader'
import ReportHeader from '../../../containers/report-header'

const ReportLowPerfromance = ({
  data,
  loading
}) => (
  <div className='container'>
    <ReportHeader title={'Low performance'} titleStyle='reportName' showDates baseReportName='discipline' />
    {loading
      ? <Loader centered size='lg' />
      : (
        <div className='row'>
          <div className='col-12'>
            <Category
              title='Not enough code'
              emptyText='There are no developers with such problem'
            >
              {data && data.not_enough_code && data.not_enough_code.map(d =>
                <Developer key={d.id} data={d} />
              )}
            </Category>
            <Category
              title='Not enough productive days'
              emptyText='There are no developers with such problem'
            >
              {data && data.not_enough_productive_days && data.not_enough_productive_days.map(d =>
                <Developer key={d.id} data={d} />
              )}
            </Category>
            <Category
              title='No problems with performance'
              emptyText='There are no such developers'
            >
              {data && data.no_performance_problems && data.no_performance_problems.map(d =>
                <Developer key={d.id} data={d} />
              )}
            </Category>
          </div>
        </div>
      )
    }
  </div>
)

export default ReportLowPerfromance
