import _ from 'lodash'

import {
  jsonPost,
  regroupReportHistoryResponse
} from './utils'

export function fetchQualityRisksOverview (companyId, teamId) {
  return jsonPost(
    `dashboard/qualityRisks/qualityRisksOverview`,
    {companyId, teamId}
  )
}

export function fetchQualityRisksHistory (companyId, teamId) {
  return jsonPost(
    `dashboard/qualityRisks/qualityRisksHistory`,
    {companyId, teamId}
  ).then(regroupReportHistoryResponse)
}

export function fetchTooManyFixedTasks (companyId, teamId) {
  return jsonPost(
    'dashboard/qualityRisks/tooManyFixedTasks',
    {companyId, teamId}
  ).then(devs => ({
    developers_with_too_many_fixed_tasks: _.orderBy(devs.developers_with_too_many_fixed_tasks, 'fixed_tasks', 'desc'),
    no_fixed_tasks_problems: _.orderBy(devs.no_fixed_tasks_problems, 'fixed_tasks', 'desc'),
  }))
}

export function fetchTooManyFixedCommits (companyId, teamId) {
  return jsonPost(
    'dashboard/qualityrisks/tooManyFixedCommits',
    {companyId, teamId}
  ).then(devs => ({
    developers_with_too_many_fixed_commits: _.orderBy(devs.developers_with_too_many_fixed_commits, 'fixed_commits', 'desc'),
    no_fixed_commits_problems: _.orderBy(devs.no_fixed_commits_problems, 'fixed_commits', 'desc'),
  }))
}
