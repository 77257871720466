import React from 'react'
import ReportHeader from '../../../containers/report-header'
import ReportDates from '../../../components/report-dates'

import CardOverview from './card-overview'
import {
  LowPerformance,
  Overwork,
  Violations
} from './card-list'

const ReportDiscipline = ({
  overview,
  lowPerformance,
  toLowPerformance,
  overwork,
  toOverwork,
  violations,
  toViolations,
  maxReportDate
}) => (
  <div className='container'>
    <ReportHeader title={'Discipline'} titleStyle='reportName' />
    <div className='row'>
      <CardOverview
        className='col'
        loading={overview.loading}
        data={overview.data}
      />
    </div>
    <div className='row'>
      <ReportDates
        className='col'
        lastDate={maxReportDate}
        days={30}
        type='report'
      />
    </div>
    <div className='row'>
      <LowPerformance
        className='col-4'
        data={lowPerformance.data}
        loading={lowPerformance.loading}
        onClick={toLowPerformance}
      />
      <Overwork
        className='col-4'
        data={overwork.data}
        loading={overwork.loading}
        onClick={toOverwork}
      />
      <Violations
        className='col-4'
        data={violations.data}
        loading={violations.loading}
        onClick={toViolations}
      />
    </div>
  </div>
)

export default ReportDiscipline
