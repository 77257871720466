import React from 'react'
import { NavLink } from 'react-router-dom'
import _ from 'lodash'

import s from './index.module.css'

const prepareBreadcrumbs = (location, routes) => {
  return ['', ...location.pathname.split('/').filter(p => !!p)]
    .reduce((a, v, i) => {
      if (i === 0) {
        a.push('/')
      } else if (a.length === 1) {
        a.push(`/${v}`)
      } else {
        a.push(`${a[a.length - 1]}/${v}`)
      }
      return a
    }, [])
    .map(p => _.find(routes, {path: p}))
    .filter(r => !!r)
    .map(r => ({
      to: {...location, pathname: r.path},
      breadcrumb: r.breadcrumb
    }))
}

const Breadcrumbs = ({ routes, location }) => {
  if (!location || location.pathname === '/') {
    return null
  }
  const breadcrumbs = prepareBreadcrumbs(location, routes)
  return <div className='container'>
    <div className='row align-items-center'>
      <div className='col'>
        {breadcrumbs.map(({ breadcrumb, to }, i) => (
          <span key={to.pathname}>
            <NavLink to={to} className={s.link} activeClassName={s.activeLink} exact>
              {breadcrumb}
            </NavLink>
            {i === breadcrumbs.length - 1 ? null : <span className={s.separator}>/</span>}
          </span>
        ))}
      </div>
    </div>
  </div>
}

export default Breadcrumbs
