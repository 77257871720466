import React from 'react'
import classnames from 'classnames'
import 'loaders.css/loaders.min.css'
import './loader.css'

export default ({className, size, centered}) => (
  <div className={
    classnames(
      className,
      'loader-base',
      `loader-size-${size || 'md'}`,
      centered ? 'loader-centered' : null
    )}>
    <div className='loader-inner ball-pulse'>
      <div/>
      <div/>
      <div/>
    </div>
  </div>
)
