import React from 'react'

import Category from '../../../components/report-details-category'
import Developer from './developer'
import Loader from '../../../components/loader'
import ReportHeader from '../../../containers/report-header'

const ReportLowPerfromance = ({
  data,
  loading
}) => (
  <div className='container'>
    <ReportHeader title={'Violations'} titleStyle='reportName' showDates baseReportName='discipline' />
    {loading
      ? <Loader centered size='lg' />
      : (
        <div className='row'>
          <div className='col-12'>
            <Category
              title='Too many commits without task prefix'
              emptyText='There are no developers with such problem'
            >
              {data && data.commits_without_task_prefix && data.commits_without_task_prefix.map(d =>
                <Developer key={d.id} data={d} />
              )}
            </Category>
            <Category
              title='No problems with task prefixes'
              emptyText='There are no such developers'
            >
              {data && data.no_commits_without_task_prefix_problems && data.no_commits_without_task_prefix_problems.map(d =>
                <Developer key={d.id} data={d} />
              )}
            </Category>
          </div>
        </div>
      )
    }
  </div>
)

export default ReportLowPerfromance
