import React from 'react'

import s from './hints.module.css'

export default {
  discipline: (
    <div className={s.hint}>
      Developers who violate working disciplines such as:
      <br />
      low performance, infrequent commits, overworking and git rule violations.
    </div>
  ),
  busFactor: (
    <div className={s.hint}>
      Here you can see developers who are too much unique.
      If a unique developer quits someday, it will have an impact on company's work.
      To prevent such risks try to minimize uniqueness of developers in advance.
    </div>
  ),
  qualityRisks: (
    <div className={s.hint}>
      Developers who produce the most bugs.
    </div>
  ),
  timeLeaks: (
    <div className={s.hint}>
      Work time wasted.
    </div>
  ),
  lowPerformance: (
    <div className={s.hint}>
      Developers who didn’t reach a minimal monthly threshold of code are put into the “Not enough code” group.
      <br />
      Developers who have enough code but not enough productive days are into the "Not enough productive days" group.
      <br />
      Productive day is a day when developer commits more code than a minimal daily threshold of code amount.
    </div>
  ),
  overwork: (
    <div className={s.hint}>
      Developers which might be overloaded.
      For developers listed here you might want to check their calendars or redistribute teamwork.
    </div>
  ),
  violations: (
    <div className={s.hint}>
      Developers that violate the rules of working with git.
    </div>
  ),
  fixedTasks: (
    <div className={s.hint}>
      If a developer has a high share of commits with task prefixes we try to calculate his Fixed tasks. We search for each developer's tasks that were fixed as Bugs.
      <br />
      If a developer has a high share of fixed tasks you might want yo check the following:
      <br />
      1. Awareness of the code base for the developer – in case it's low it might be the reason.
      <br />
      2. How much time the developer spends working with the requirements.
      <br />
      3. Requirements quality from the product manager.
      <br />
      4. Average size of the task - when larger than 3 days it also might be the reason.
    </div>
  ),
  fixedCommits: (
    <div className={s.hint}>
      If a developer has a low share of commits with task prefixes we try to calculate his Fixed commits.
      <br />
      We search for commits of each developer that were fixed as a Bug.
      <br />
      It's less accurate than Fixed tasks but still useful.
    </div>
  ),
  churnByTasks: (
    <div className={s.hint}>
      Churn is a share of the developer's lines of code that were added during working on a task and later removed before the release. This metric shows the share of time that was wasted. It signals that the developer spends not enough time working with the requirements before the implementation.
    </div>
  ),
  churnByIntervals: (
    <div className={s.hint}>
      This metrics helps when we can’t calculate Churn of code in tasks.
      <br />
      It's also useful when developer don’t specify task prefix in commits.
      <br />
      Churn is calculated using merge date of each line. It is less accurate than Churn calculated by tasks but still useful.
    </div>
  )
}
