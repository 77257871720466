import React from 'react'

import ReportHeader from '../../../containers/report-header'
import ReportDates from '../../../components/report-dates'
import CardWithChart from './card-with-chart'
import { CardWithTimeLeaks, CardWithBusFactor } from './card-with-list'
import hints from '../../../strings/hints'

const ReportMain = ({
  discipline, busFactor, qualityRisks, timeLeaks,
  toDiscipline, toBusFactor, toQualityRisks, toTimeLeaks,
  maxCompanyDate
}) => (
  <div className='container'>
    <ReportHeader title={<ReportDates lastDate={maxCompanyDate} days={30} type='main' />} titleStyle='text' />
    <div className='row'>
      <CardWithChart
        className='col-3'
        title='Discipline'
        hint={hints.discipline}
        state={discipline}
        onClick={toDiscipline}
        chartColor='#e91e63'
      />
      <CardWithBusFactor
        className='col-3'
        title='Bus Factor'
        hint={hints.busFactor}
        state={busFactor}
        onClick={toBusFactor}
      />
      <CardWithChart
        className='col-3'
        title='Quality Risks'
        hint={hints.qualityRisks}
        state={qualityRisks}
        onClick={toQualityRisks}
        chartColor='#ff8f00'
      />
      <CardWithTimeLeaks
        className='col-3'
        title='Time Leaks'
        hint={hints.timeLeaks}
        state={timeLeaks}
        onClick={toTimeLeaks}
      />
    </div>
  </div>
)

export default ReportMain
