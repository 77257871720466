import React from 'react'
import classnames from 'classnames'

import CardWithTitle from '../../../../components/card-with-title'
import MainText from './main-text'
import List from './list'
import {TimeLeaksDevRow, BusFactorDevRow} from './rows'
import s from './index.module.css'

export const CardWithTimeLeaks = ({state, title, hint, className, onClick}) => {
  const body = !state.data ? null : (
    <div className='row'>
      <div className='col'>
        <MainText label='LEAKED TIME'>
          <span className={classnames(s.mainTextLarge, 'align-middle')}>{state.data.time_leaks_abs}</span>
          <span className={classnames(s.mainTextMedium, 'align-middle')}>h</span>
          <span className={classnames(s.mainTextSmall, s.mainTextSmallMargin, 'align-middle')}>
            {Math.round(state.data.time_leaks_rel * 100)}%
          </span>
        </MainText>
        <List
          data={state.data.developers_with_time_leaks}
          row={TimeLeaksDevRow}
          emptyText='There are no developers with high time leaks'
        />
      </div>
    </div>
  )
  return (
    <CardWithTitle
      title={title}
      hint={hint}
      withHover
      className={classnames(className, s.card)}
      loading={state.loading}
      empty={!state.data}
      onClick={onClick}
    >
      {body}
    </CardWithTitle>
  )
}

export const CardWithBusFactor = ({state, title, hint, className, onClick}) => {
  const body = !state.data ? null : (
    <div className='row'>
      <div className='col'>
        <MainText label='PROBLEM DEVELOPERS'>
          <span className={classnames(s.mainTextLarge, 'align-middle')}>{state.data.with_problems}</span>
          <span className={classnames(s.mainTextSmall, 'align-middle')}>{` / ${state.data.all}`}</span>
        </MainText>
        <List
          data={state.data.developers}
          row={BusFactorDevRow}
          emptyText='There are no developers with high uniqueness'
        />
      </div>
    </div>
  )
  return (
    <CardWithTitle
      title={title}
      hint={hint}
      withHover
      className={classnames(className, s.card)}
      loading={state.loading}
      empty={!state.data}
      onClick={onClick}
    >
      {body}
    </CardWithTitle>
  )
}
