import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { withRouter } from 'react-router-dom'

import App from '../components/app'
import Loader from '../../../components/loader'
import { getCompanies, selectCompany } from '../../../modules/_global/actions/companies'

const mapStateToProps = state => ({
  location: state.router.location, // to have opportunity to use routing
  loading: state._global.companies.loading,
  companies: state._global.companies.list,
  currentCompany: state._global.companies.currentCompany,
  queryCurrentCompany: state._global.companies.queryCurrentCompany
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getCompanies,
  selectCompany,
  toMain: () => push('/')
}, dispatch)

class AppConatiner extends PureComponent {
  componentDidMount () {
    this.props.getCompanies()
  }

  componentDidUpdate (prevProps) {
    if (this.props.queryCurrentCompany !== this.props.currentCompany && this.props.currentCompany !== null) {
      this.props.selectCompany(this.props.companies[0].id, 'replace')
    }
  }

  render () {
    const { routes, location, companies, loading, currentCompany, selectCompany, toMain } = this.props
    if (loading) {
      return <Loader centered size='lg' />
    } else {
      return <App
        location={location}
        routes={routes}
        companies={companies}
        currentCompany={currentCompany}
        selectCompany={id => id !== currentCompany && selectCompany(id)}
        toMain={toMain}
      />
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppConatiner))
