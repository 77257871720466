import React from 'react'
import pluralize from 'pluralize'
import ReactTooltip from 'react-tooltip'
import classnames from 'classnames'

import DeveloperRow, { Param, Name } from '../../../../components/report-details-developer-row'
import s from './index.module.css'
import {uniqnessCategory} from '../../strings'

const TeamSize = ({data}) => (
  <div>
    <div data-tip data-for={`team_size_${data.id}`}>
      {pluralize('developer', (data.team && data.team.length) || 0, true)}
    </div>
    <ReactTooltip id={`team_size_${data.id}`} effect='solid'>
      {!(data.team && data.team.length)
        ? (<div className={s.teamSizeTooltipHeader}>No developers worked in the same repos</div>)
        : (
          <div>
            <div className={s.teamSizeTooltipHeader}>Developers working in same repos:</div>
            {data.team.slice(0, 5).map(d => (
              <div key={`team_size_${data.id}_${d}`} className={s.teamSizeTooltipDeveloper}>{d}</div>
            ))}
            {data.team.length <= 5 ? null : (
              <div className={s.teamSizeMore}>+{data.team.length - 3} more...</div>
            )}
          </div>
        )
      }
    </ReactTooltip>
  </div>
)

const WorkingRepos = ({data}) => (
  <div>
    <div data-tip data-for={`working_repos_${data.id}`}>
      {pluralize('repo', (data.active_repos && data.active_repos.length) || 0, true)}
    </div>
    <ReactTooltip id={`working_repos_${data.id}`} effect='solid'>
      {!(data.active_repos && data.active_repos.length)
        ? (<div className={s.teamSizeTooltipHeader}>No working repos</div>)
        : (
          <div>
            <div className={s.teamSizeTooltipHeader}>Woking repos:</div>
            {data.active_repos.slice(0, 5).map(d => (
              <div key={`working_repos_${data.id}_${d}`} className={s.teamSizeTooltipDeveloper}>{d}</div>
            ))}
            {data.active_repos.length <= 5 ? null : (
              <div className={s.teamSizeMore}>+{data.team.length - 3} more...</div>
            )}
          </div>
        )
      }
    </ReactTooltip>
  </div>
)

export default ({
  data
}) => (
  <DeveloperRow>
    <Name
      className={classnames('col-auto', s.name)}
      name={data.name}
    />
    <Param
      className={classnames('col-auto', s.uniqueness)}
      label='Uniqueness risk'
      value={`${uniqnessCategory(data.uniqueness_problem_category)}`}
    />
    <Param
      className='col'
      label='Unique files vs All own files'
      value={`${Math.round(data.self_uniqueness * 100)}%`}
      valueSecond={` (${data.author_unique_files} of ${data.author_all_files} files)`}
    />
    <Param
      className='col'
      label='Unique files vs All team files'
      value={`${Math.round(data.author_unique_files / data.team_files * 100)}%`}
      valueSecond={` (${data.author_unique_files} of ${data.team_files} files)`}
    />
    <Param
      className={classnames('col-auto', s.repos)}
      label='Team size'
      value={<TeamSize data={data} />}
    />
    <Param
      className={classnames('col-auto', s.teamSize)}
      label='Working repos'
      value={<WorkingRepos data={data} />}
    />
  </DeveloperRow>
)
