import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import ReportFixedCommits from '../components/report-fixed-commits'
import {
  getTooManyFixedCommits
} from '../../../modules/report-quality-risks/actions/requests'

const mapStateToProps = state => ({
  currentTeam: state._global.companyData.currentTeam,
  currentCompany: state._global.companies.currentCompany,

  tooManyFixedCommits: _.pick(state.reportQualityRisks.tooManyFixedCommits, ['loading', 'data'])
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getTooManyFixedCommits
}, dispatch)

class ReportFixedCommitsContainer extends PureComponent {
  componentDidMount () {
    this.props.getTooManyFixedCommits()
  }

  componentDidUpdate (prevProps) {
    if (
      this.props.currentCompany !== prevProps.currentCompany ||
      this.props.currentTeam !== prevProps.currentTeam
    ) {
      this.props.getTooManyFixedCommits()
    }
  }

  render () {
    const { tooManyFixedCommits } = this.props
    return <ReportFixedCommits
      data={tooManyFixedCommits.data}
      loading={tooManyFixedCommits.loading}
    />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportFixedCommitsContainer)
