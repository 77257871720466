import { combineReducers } from 'redux'

import {
  OVERVIEW_REQUESTED,
  OVERVIEW,
  LOW_PERFORMANCE_REQUESTED,
  LOW_PERFORMANCE,
  OVERWORK_REQUESTED,
  OVERWORK,
  VIOLATIONS_REQUESTED,
  VIOLATIONS
} from '../constants'
import { requestReducer } from '../../utils-reducers'

export default combineReducers({
  overview: requestReducer(OVERVIEW_REQUESTED, OVERVIEW),
  lowPerformance: requestReducer(LOW_PERFORMANCE_REQUESTED, LOW_PERFORMANCE),
  overwork: requestReducer(OVERWORK_REQUESTED, OVERWORK),
  violations: requestReducer(VIOLATIONS_REQUESTED, VIOLATIONS)
})
