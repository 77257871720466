import React from 'react'
import classnames from 'classnames'

import s from './index.module.css'
import {uniqnessCategory} from '../../../report-bus-factor/strings'

export const TimeLeaksDevRow = ({data}) => (
  <div className={classnames('row justify-content-between align-items-center', s.dev)}>
    <div className={classnames('col', s.devName)}>
      {data.name}
    </div>
    <div className={classnames('col-auto', s.devTime)}>
      {data.leaked_hours}h
    </div>
  </div>
)

export const BusFactorDevRow = ({data}) => (
  <div className={classnames('row justify-content-between align-items-center', s.dev)}>
    <div className={classnames('col', s.devName)}>
      {data.name}
    </div>
    <div className={classnames('col-auto', s.devTime)}>
      {uniqnessCategory(data.uniqueness_problem_category)} risk
    </div>
  </div>
)
