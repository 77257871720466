import _ from 'lodash'

import {
  jsonPost,
  regroupReportHistoryResponse
} from './utils'

export function fetchDisciplineOverview (companyId, teamId) {
  return jsonPost(
    `dashboard/discipline/disciplineOverview`,
    {companyId, teamId}
  )
}

export function fetchDisciplineHistory (companyId, teamId) {
  return jsonPost(
    `dashboard/discipline/disciplineHistory`,
    {companyId, teamId}
  ).then(regroupReportHistoryResponse)
}

export function fetchLowPerformance (companyId, teamId) {
  return jsonPost(
    'dashboard/discipline/lowPerformance',
    {companyId, teamId}
  ).then(data => ({
    not_enough_code: _.orderBy(data.not_enough_code, 'additions', 'asc'),
    not_enough_productive_days: _.orderBy(data.not_enough_productive_days, 'productive_days', 'asc'),
    no_performance_problems: _.orderBy(data.no_performance_problems, 'productive_days', 'asc'),
  }))
}
export function fetchOverwork (companyId, teamId) {
  return jsonPost(
    'dashboard/discipline/overwork',
    {companyId, teamId}
  ).then(data => ({
    too_much_weekend_work: _.orderBy(data.too_much_weekend_work, 'weekend_pure_additions', 'desc'),
    no_overwork_problems: _.orderBy(data.no_overwork_problems, 'weekend_pure_additions', 'desc')
  }))
}
export function fetchViolations (companyId, teamId) {
  return jsonPost(
    'dashboard/discipline/violations',
    {companyId, teamId}
  ).then(data => ({
    commits_without_task_prefix:
      _.orderBy(data.commits_without_task_prefix, ['commits_with_task_share', 'all_commits'], ['asc', 'desc']),
    no_commits_without_task_prefix_problems:
      _.orderBy(data.no_commits_without_task_prefix_problems, ['commits_with_task_share', 'all_commits'], ['asc', 'desc']),
  }))
}
