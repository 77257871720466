import React from 'react'
import moment from 'moment'
import pluralize from 'pluralize'
import classnames from 'classnames'

import s from './index.module.css'

const reportsDates = (date, days) =>
  moment(date).startOf('day').subtract(+days, 'day').format('ddd, DD MMM YYYY') + ' – ' +
  moment(date).startOf('day').format('ddd, DD MMM YYYY')

export default ({className, lastDate, days, type}) => (
  <div className={classnames(className, {
    [s.inMain]: type === 'main',
    [s.inReport]: type === 'report',
    [s.inDetails]: type === 'details'
  })}>
    <span className={s.text}>
      Last {pluralize('day', days, true)} problems
      <span className={s.dates}>{reportsDates(lastDate, days)}</span>
    </span>
  </div>
)
