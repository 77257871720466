import React from 'react'
import App from './containers/app'
import ReportMain from '../report-main'
import ReportDiscipline from '../report-discipline'
import ReportLowPerformance from '../report-low-performance'
import ReportOverwork from '../report-overwork'
import ReportViolations from '../report-violations'
import ReportQualityRisks from '../report-quality-risks'
import ReportFixedTasks from '../report-fixed-tasks'
import ReportFixedCommits from '../report-fixed-commits'
import ReportTimeLeaks from '../report-time-leaks'
import ReportChurnByTasks from '../report-churn-by-tasks'
import ReportChurnByInterval from '../report-churn-by-interval'
import ReportBusFactor from '../report-bus-factor'

const routes = [
  {
    path: '/',
    breadcrumb: 'Home',
    component: ReportMain,
    exact: true
  },
  {
    path: '/discipline',
    breadcrumb: 'Discipline',
    component: ReportDiscipline,
    exact: true
  },
  {
    path: '/discipline/low-performance',
    breadcrumb: 'Low performance',
    component: ReportLowPerformance,
    exact: true
  },
  {
    path: '/discipline/overwork',
    breadcrumb: 'Overwork',
    component: ReportOverwork,
    exact: true
  },
  {
    path: '/discipline/violations',
    breadcrumb: 'Violations',
    component: ReportViolations,
    exact: true
  },
  {
    path: '/quality-risks',
    breadcrumb: 'Quality risks',
    component: ReportQualityRisks,
    exact: true
  },
  {
    path: '/quality-risks/fixed-tasks',
    breadcrumb: 'Fixed tasks',
    component: ReportFixedTasks,
    exact: true
  },
  {
    path: '/quality-risks/fixed-commits',
    breadcrumb: 'Fixed commits',
    component: ReportFixedCommits,
    exact: true
  },
  {
    path: '/time-leaks',
    breadcrumb: 'Time leaks',
    component: ReportTimeLeaks,
    exact: true
  },
  {
    path: '/time-leaks/churn-by-tasks',
    breadcrumb: 'Churn of code in tasks',
    component: ReportChurnByTasks,
    exact: true
  },
  {
    path: '/time-leaks/churn-by-interval',
    breadcrumb: 'Churn of all code',
    component: ReportChurnByInterval,
    exact: true
  },
  {
    path: '/bus-factor',
    breadcrumb: 'Bus factor',
    component: ReportBusFactor,
    exact: true
  }
]

export default () => (
  <App routes={routes} />
)
