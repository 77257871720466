import React from 'react'
import classnames from 'classnames'

import CardSimple from '../card-simple'
import s from './index.module.css'

export const Name = ({
  className,
  name
}) => (
  <div className={className}>
    <span className={s.name}>{name}</span>
  </div>
)
export const Param = ({
  className,
  label,
  value,
  valueSecond,
  valueHelp
}) => (
  <div className={className}>
    <div className={classnames('row align-items-center', s.param)}>
      <div className='col'>
        <span className={s.paramLabel}>{label}</span>
      </div>
    </div>
    <div className={classnames('row align-items-center', s.param)}>
      <div className='col'>
        <span className={s.paramValue}>{value}</span>
        <span className={s.paramValueSecond}>{valueSecond}</span>
        <span className={s.paramValueHelp}>{valueHelp}</span>
      </div>
    </div>
  </div>
)

const DeveloperRow = ({
  children,
  className
}) => (
  <div className={classnames('row', s.developer, className)}>
    <CardSimple className='col-12' withHover={false}>
      <div className='row align-items-center'>
        {children}
      </div>
    </CardSimple>
  </div>
)

export default DeveloperRow
