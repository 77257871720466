import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import ReportBusFactor from '../components/report-bus-factor'
import {
  getTooUnique
} from '../../../modules/report-bus-factor/actions/requests'

const mapStateToProps = state => ({
  currentTeam: state._global.companyData.currentTeam,
  currentCompany: state._global.companies.currentCompany,

  tooUnique: _.pick(state.reportBusFactor.tooUnique, ['loading', 'data'])
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getTooUnique
}, dispatch)

class ReportBusFactorContainer extends PureComponent {
  componentDidMount () {
    this.props.getTooUnique()
  }

  componentDidUpdate (prevProps) {
    if (
      this.props.currentCompany !== prevProps.currentCompany ||
      this.props.currentTeam !== prevProps.currentTeam
    ) {
      this.props.getTooUnique()
    }
  }

  render () {
    const { tooUnique } = this.props
    return <ReportBusFactor
      data={tooUnique.data}
      loading={tooUnique.loading}
    />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportBusFactorContainer)
