import React from 'react'

import Category from '../../../components/report-details-category'
import Developer from './developer'
import Loader from '../../../components/loader'
import ReportHeader from '../../../containers/report-header'

const ReportFixedCommits = ({
  data,
  loading
}) => (
  <div className='container'>
    <ReportHeader title={'Fixed commits'} titleStyle='reportName' showDates baseReportName='qualityRisks' />
    {loading
      ? <Loader centered size='lg' />
      : (
        <div className='row'>
          <div className='col-12'>
            <Category
              title='Too many fixed commits'
              emptyText='There are no developers with such problem'
            >
              {data && data.developers_with_too_many_fixed_commits && data.developers_with_too_many_fixed_commits.map(d =>
                <Developer key={d.id} data={d} />
              )}
            </Category>
            <Category
              title='No fixed commits problem'
              emptyText='There are no such developers'
            >
              {data && data.no_fixed_commits_problems && data.no_fixed_commits_problems.map(d =>
                <Developer key={d.id} data={d} />
              )}
            </Category>
          </div>
        </div>
      )
    }
  </div>
)

export default ReportFixedCommits
