import React from 'react'

import DeveloperRow, { Param, Name } from '../../../components/report-details-developer-row'

export default ({
  data
}) => (
  <DeveloperRow>
    <Name
      className='col'
      name={data.name}
    />
    <Param
      className='col-3'
      label='Leaked hours'
      value={`${data.leaked_hours} of ${data.active_hours}`}
      valueSecond={` (${Math.round(data.churn * 100)}%)`}
    />
    <Param
      className='col-3'
      label='Churned lines of code'
      value={`${Math.round(data.churned_lines)} of ${data.all_lines}`}
      valueSecond={` (${Math.round(data.churn * 100)}%)`}
    />
    <Param
      className='col'
      label='Confidence about hours'
      value={data.confindent_about_hours ? 'High' : 'Low'}
      valueSecond={` (${data.active_days} active days)`}
    />
  </DeveloperRow>
)
