import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import ReportMain from '../components/report-main'
import {
  getDiscipline,
  getBusFactor,
  getQualityRisks,
  getTimeLeaks
} from '../../../modules/report-main/actions/requests'
import {
  toDiscipline,
  toBusFactor,
  toQualityRisks,
  toTimeLeaks
} from '../../../modules/report-main/actions/routing'

const mapStateToProps = state => ({
  currentTeam: state._global.companyData.currentTeam,
  currentCompany: state._global.companies.currentCompany,

  maxCompanyDate: state._global.companyData.maxCompanyDate,

  discipline: _.pick(state.reportMain.discipline, ['loading', 'data']),
  busFactor: _.pick(state.reportMain.busFactor, ['loading', 'data']),
  qualityRisks: _.pick(state.reportMain.qualityRisks, ['loading', 'data']),
  timeLeaks: _.pick(state.reportMain.timeLeaks, ['loading', 'data'])
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getDiscipline,
  getBusFactor,
  getQualityRisks,
  getTimeLeaks,
  toDiscipline,
  toBusFactor,
  toQualityRisks,
  toTimeLeaks
}, dispatch)

class ReportMainContainer extends PureComponent {
  componentDidMount () {
    this.props.getDiscipline()
    this.props.getBusFactor()
    this.props.getQualityRisks()
    this.props.getTimeLeaks()
  }

  componentDidUpdate (prevProps) {
    if (
      this.props.currentCompany !== prevProps.currentCompany ||
      this.props.currentTeam !== prevProps.currentTeam
    ) {
      this.props.getDiscipline()
      this.props.getBusFactor()
      this.props.getQualityRisks()
      this.props.getTimeLeaks()
    }
  }

  render () {
    const {
      discipline, busFactor, qualityRisks, timeLeaks,
      toDiscipline, toBusFactor, toQualityRisks, toTimeLeaks,
      maxCompanyDate
    } = this.props

    return <ReportMain
      maxCompanyDate={maxCompanyDate}
      discipline={discipline}
      toDiscipline={toDiscipline}
      busFactor={busFactor}
      toBusFactor={toBusFactor}
      qualityRisks={qualityRisks}
      toQualityRisks={toQualityRisks}
      timeLeaks={timeLeaks}
      toTimeLeaks={toTimeLeaks}
    />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportMainContainer)
