import {jsonGet, jsonPost} from './utils'

export function fetchCompanies () {
  return jsonGet('company/list')
}

export function fetchTeams (companyId) {
  return jsonGet(`company/${companyId}/teams`)
}

export function fetchAuthorsInTeams (companyId) {
  return jsonGet(`company/${companyId}/authorsinteams`)
}

export function fetchAuthorsInRepos (companyId) {
  return jsonGet(`company/${companyId}/authorsinrepos`)
}

export function fetchAuthors (companyId) {
  return jsonGet(`company/${companyId}/authors`)
}

export function fetchRepos (companyId) {
  return jsonGet(`company/${companyId}/repos`)
}

export function fetchMaxCompanyDate (companyId) {
  return jsonGet(`company/${companyId}/maxcompanydate`)
}

export function fetchReportsMaxDates (companyId) {
  return Promise.all([
    jsonPost(`dashboard/maxReportDate`, {companyId, report: 'discipline'}),
    jsonPost(`dashboard/maxReportDate`, {companyId, report: 'bus_factor'}),
    jsonPost(`dashboard/maxReportDate`, {companyId, report: 'quality_risks'}),
    jsonPost(`dashboard/maxReportDate`, {companyId, report: 'time_leaks'}),
  ])
  .then(([discipline, busFactor, qualityRisks, timeLeaks]) => ({
    discipline,
    busFactor,
    qualityRisks,
    timeLeaks
  }))
}

export function fetchCompanyData (companyId) {
  return Promise.all([
    fetchAuthors(companyId),
    fetchRepos(companyId),
    fetchTeams(companyId),
    fetchAuthorsInRepos(companyId),
    fetchAuthorsInTeams(companyId),
    fetchMaxCompanyDate(companyId),
    fetchReportsMaxDates(companyId)
  ]).then(([
    authors,
    repos,
    teams,
    authorsInRepos,
    authorsInTeams,
    maxCompanyDate,
    maxReportsDates
  ]) => ({
    authors,
    repos,
    teams,
    authorsInRepos,
    authorsInTeams,
    maxCompanyDate,
    maxReportsDates
  }))
}
