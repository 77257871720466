import { combineReducers } from 'redux'

import {
  OVERVIEW_REQUESTED,
  OVERVIEW,
  CHURN_BY_TASKS_REQUESTED,
  CHURN_BY_TASKS,
  CHURN_BY_INTERVAL_REQUESTED,
  CHURN_BY_INTERVAL
} from '../constants'
import { requestReducer } from '../../utils-reducers'

export default combineReducers({
  overview: requestReducer(OVERVIEW_REQUESTED, OVERVIEW),
  churnByTasks: requestReducer(CHURN_BY_TASKS_REQUESTED, CHURN_BY_TASKS),
  churnByInterval: requestReducer(CHURN_BY_INTERVAL_REQUESTED, CHURN_BY_INTERVAL)
})
