import qs from 'query-string'
import _ from 'lodash'

import { COMPANY_DATA, COMPANY_DATA_REQUESTED, SELECT_COMPANY, SELECT_TEAM } from '../constants'
import { LOCATION_CHANGE } from 'connected-react-router'

const initialState = {
  authors: [],
  repos: [],
  teams: [],
  authorsInRepos: [],
  authorsInTeams: [],
  maxReportsDates: {},
  currentTeam: null,
  queryCurrentTeam: null,
  request: null,
  loading: true
}

const safeCurrent = (list, queryCurrent) => _.get(_.find(list, {id: queryCurrent}), 'id', null)

export default (state = initialState, action) => {
  switch (action.type) {
    case COMPANY_DATA:
      const { teams } = action.payload
      return {
        ...state,
        ...action.payload,
        request: null,
        loading: false,
        currentTeam: safeCurrent(teams, state.queryCurrentTeam)
      }

    case COMPANY_DATA_REQUESTED:
      state.request && state.request.cancel()
      return {
        ...state,
        authors: [],
        repos: [],
        teams: [],
        authorsInRepos: [],
        authorsInTeams: [],
        maxReportsDates: {},
        currentTeam: null,
        request: action.payload.request,
        loading: true
      }

    case SELECT_COMPANY:
      return {
        ...initialState
      }

    case SELECT_TEAM:
      return {
        ...state,
        currentTeam: action.payload.id
      }

    case LOCATION_CHANGE:
      const id = +qs.parse(action.payload.location.search).teamId || null
      return {
        ...state,
        currentTeam: state.currentTeam !== id ? null : id,
        queryCurrentTeam: id
      }

    default:
      return state
  }
}
